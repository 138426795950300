import qs from 'qs';
import request from '@/utils/request';

export async function page(payload) {
  const res = await request(`/fragments${qs.stringify(payload, { addQueryPrefix: true })}`);
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function group(payload) {
  const res = await request(`/fragments/group${qs.stringify(payload, { addQueryPrefix: true })}`);
  return { success: true, data: res };
}

export async function getById(id) {
  return request(`/fragments/${id}`);
}

export async function create(payload) {
  return request(
    `/fragments`,
    {
      method: 'POST',
      body: payload,
    },
    false
  );
}

export async function createBatch(payload) {
  return request(
    `/fragments/batch`,
    {
      method: 'POST',
      body: payload,
    },
    false
  );
}

export async function cancel(id) {
  return request(`/fragments/cancel/${id}`, { method: 'POST' });
}

export async function cancelBatch(ids) {
  return request(`/fragments/cancel/batch`, {
    method: 'POST',
    body: ids,
  });
}

export async function importJobs(body) {
  return request(
    '/fragments/import',
    {
      method: 'POST',
      body,
    },
    false
  );
}
