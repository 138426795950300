import { saveTourTimePoint, deleteTourTimePoint, getTourTimePointList, updateTourTimePoint } from '@/services';
import { getTourTimePointType, getTourTimePointTypeMode } from '@/utils/tour';
import { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { PageContainer } from '@ant-design/pro-layout';
import { useCallback, useRef, useState } from 'react';
import { columns } from './tour.time.point.columns';
import { Button, Popconfirm, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';
import { useParams } from 'react-router';

const TourTimePointList = () => {
  const { tourId } = useParams();

  const actionRef = useRef();
  const [point, setPoint] = useState();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const fetchTourTimePoint = useCallback(
    async (params, sort) => {
      return getTourTimePointList({ ...params, tourId }, sort);
    },
    [tourId]
  );

  const addSubmit = useCallback(
    async values => {
      const hide = message.loading('processing');
      const type = typeof values.type === 'string' ? [values.type] : values.type;
      const res = await saveTourTimePoint({ ...values, type, tourId });
      hide();
      if (res && res.id) {
        setShowAddForm(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      }
    },
    [tourId, setShowAddForm, actionRef]
  );

  const editSubmit = useCallback(
    async values => {
      const hide = message.loading('processing');
      const type = typeof values.type === 'string' ? [values.type] : values.type;
      const res = await updateTourTimePoint(point.id, {
        ...values,
        type,
        tourId,
      });
      hide();
      if (res && res.id) {
        setShowEditForm(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      }
    },
    [tourId, setShowEditForm, actionRef, point]
  );

  const deleteSubmit = useCallback(
    async values => {
      const hide = message.loading('processing');
      const success = await deleteTourTimePoint(values.id);
      hide();
      if (success) {
        if (actionRef.current) {
          actionRef.current.reload();
        }
      }
    },
    [actionRef]
  );

  const action = {
    title: 'Action',
    key: 'action',
    valueType: 'option',
    render: (text, record) => [
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        key={`edit-${record.id}`}
        onClick={() => {
          setPoint({ ...record, timePoint: record.timePoint.substring(0, 5) });
          setShowEditForm(true);
        }}
      >
        Edit
      </a>,
      <Popconfirm
        key={`delete-${record.id}`}
        title="Are you sure to delete this user?"
        onConfirm={() => deleteSubmit(record)}
        okText="Yes"
        cancelText="No"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>Delete</a>
      </Popconfirm>,
    ],
  };

  return (
    <PageContainer>
      <ProTable
        headerTitle="Time Point List"
        actionRef={actionRef}
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        request={fetchTourTimePoint}
        columnsState={{
          persistenceKey: 'pro-table-singe-time-point-list',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={{}}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        options={false}
        toolBarRender={() => [
          <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => setShowAddForm(true)}>
            Add
          </Button>,
        ]}
      />
      {showAddForm && (
        <ModalForm
          title="Add Tout Time Point"
          width="400px"
          visible={showAddForm}
          onVisibleChange={visible => {
            setShowAddForm(visible);
          }}
          initialValues={{}}
          onFinish={addSubmit}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
                message: 'Time invalid',
              },
            ]}
            width="md"
            name="timePoint"
            label="Time Point"
          />
          <ProFormSelect
            options={getTourTimePointType(tourId)}
            mode={getTourTimePointTypeMode(tourId)}
            width="md"
            name="type"
            label="Type"
          />
        </ModalForm>
      )}
      {showEditForm && (
        <ModalForm
          title="Edit Tout Time Point"
          width="400px"
          visible={showEditForm}
          onVisibleChange={visible => {
            setShowEditForm(visible);
          }}
          onFinish={editSubmit}
          initialValues={point}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
                message: 'Time invalid',
              },
            ]}
            width="md"
            name="timePoint"
            label="Time Point"
          />
          <ProFormSelect
            options={getTourTimePointType(tourId)}
            mode={getTourTimePointTypeMode(tourId)}
            width="md"
            name="type"
            label="Type"
          />
        </ModalForm>
      )}
    </PageContainer>
  );
};

export default TourTimePointList;
