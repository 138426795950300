import { ModalForm, ProFormDatePicker, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { updateGroup, getGroupById } from '@/services/groupAPI';
import { useCallback, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import ToursSelect from '@/components/TourSelect';
import styles from './index.module.less';
import * as moment from 'moment';

const GroupEditForm = ({ tours, showEditForm, setShowEditForm, onFinish, currentRow }) => {
  const [form] = Form.useForm();
  const [tour, setTour] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const fetchGroup = useCallback(
    async id => {
      const res = await getGroupById(id);
      if (form) {
        form.setFieldsValue({
          ...res,
          tour: [res.catalogId],
          touristList: res.tourists.map(item => `${item.firstName} ${item.lastName}`).join('\n'),
        });
        setTour(res.tour);
      }
    },
    [form]
  );

  const editSubmit = useCallback(
    async values => {
      const hide = message.loading('processing');
      const res = await updateGroup(currentRow.id, {
        name: values.name,
        catalogId: tour.catalogId,
        date: values.date,
        touristList: values.touristList ? values.touristList.split('\n').filter(a => a.trim()) : [],
      });
      hide();
      if (res && res.id) {
        setErrorMessage(null);
        setShowEditForm(false);
        onFinish();
      } else if (res && res.message) {
        setErrorMessage(res.message);
      }
    },
    [currentRow, onFinish, setShowEditForm, tour]
  );

  useEffect(() => {
    if (currentRow && currentRow.id) {
      fetchGroup(currentRow.id);
    }
  }, [currentRow, fetchGroup]);

  return (
    showEditForm && (
      <ModalForm
        form={form}
        title="Edit Group"
        width="400px"
        visible={showEditForm}
        onVisibleChange={visible => {
          setShowEditForm(visible);
          if (!visible) {
            setErrorMessage(null);
          }
        }}
        initialValues={{}}
        onFinish={editSubmit}
        disabled={currentRow.isUsed}
        className={styles.groupEditForm}
      >
        <ProFormText
          rules={[
            {
              required: true,
            },
          ]}
          width="md"
          name="name"
          label="Name"
        ></ProFormText>
        <Form.Item
          label="Available Tours"
          name="tour"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ToursSelect tours={tours} setTour={setTour} />
        </Form.Item>
        <ProFormDatePicker
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  if (moment(value).isBefore(moment())) {
                    return Promise.reject(`Date has passed`);
                  }
                  return Promise.resolve();
                } else {
                  return Promise.reject('Please enter date');
                }
              },
            },
          ]}
          width="md"
          name="date"
          label="Date"
        ></ProFormDatePicker>
        <ProFormTextArea
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Please enter tourist list');
                }
              },
            },
          ]}
          width="md"
          name="touristList"
          label="Tourist List"
        ></ProFormTextArea>
        {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
      </ModalForm>
    )
  );
};

export default GroupEditForm;
