import { fetch } from 'whatwg-fetch';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import { AuthContextValue } from '@/pages/AuthContext';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.name = response.status;
  error.response = response;
  throw error;
}

function isResponseJSON(headers) {
  return headers.get('content-type').toLowerCase().indexOf('application/json') > -1;
}

function isResponseFileStream(headers) {
  return headers.get('content-type').toLowerCase().indexOf('application/octet-stream') > -1;
}

function resolveFileNameFromContentDisposition(headers) {
  const contentDisposition = headers.get('content-disposition');
  if (!contentDisposition) {
    throw new Error('Download Error: Http not set content-disposition');
  }
  const disposition = contentDisposition.split(';');
  if (!disposition || disposition.length < 2) {
    throw new Error('Download Error: content-disposition format incorrect, it should be attachment;fileName=filename');
  }
  const fileValue = disposition[1].split('=');
  if (!fileValue[1]) {
    throw new Error('Download Error: content-disposition format incorrect, it should be attachment;fileName=filename');
  }
  return fileValue[1];
}

/**
 * read the json from response
 * @param response
 * @returns {*}
 */
function contentHandler(response) {
  const { headers } = response;
  if (isResponseJSON(headers)) {
    return response.json();
  }
  if (isResponseFileStream(headers)) {
    response.blob().then(blob => {
      const fileName = resolveFileNameFromContentDisposition(headers);
      FileSaver.saveAs(blob, fileName);
    });
    return Promise.resolve();
  }
  return response;
}

const request = async (url, options, notify = true) => {
  const defaultOptions = {
    headers: {},
  };
  // if contains jwt, append to header
  const jwt = AuthContextValue.token();
  if (jwt) {
    defaultOptions.headers.Authorization = `Bearer ${jwt}`;
  }
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'PATCH') {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  return fetch(process.env.REACT_APP_API_BASE_URL + url, newOptions)
    .then(checkStatus)
    .then(contentHandler)
    .catch(async error => {
      const response = error.response;
      const json = (await contentHandler(response)) || {};
      const message = json.message || {};
      if (notify) {
        notification.error({
          message: `${message}`,
        });
      } else {
        return json;
      }
    });
};

export default request;
