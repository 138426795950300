import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormatDateTime } from '@/components/FormatDateTime';

const { Text } = Typography;

const renderContent = notification => {
  let content;
  switch (notification.type) {
    case 'TicketAvailableChange':
      content = (
        <Text>
          The Tour
          <Link to={`/availability/${notification.tourId}`}> {notification.tourName} </Link> found
          <Text strong> {notification.tourTicketNumber} </Text>
          tickets available
        </Text>
      );
      break;
    case 'JobPurchased':
      content = (
        <Text>
          The job <Link to={`/job/${notification.jobId}`}> {notification.jobId} </Link>
          for <Text strong> {notification.tourName} </Text> was executed successfully
        </Text>
      );
      break;
    case 'JobFailed':
      content = (
        <Text>
          The job <Link to={`/job/${notification.jobId}`}> {notification.jobId} </Link>
          for <Text strong> {notification.tourName} </Text> was executed failed
        </Text>
      );
      break;
    case 'TicketDownloadable':
      content = (
        <Text>
          <Text strong> {notification.tourName} </Text>
          tickets purchased for schedule
          <Link to={`/schedule?catalogId=${notification.schedule.tourId}&fromDate=${notification.schedule.fromDate}`}>
            {' '}
            {notification.scheduleId}{' '}
          </Link>{' '}
          are available to
          <Link to={`/ticket`}> downloaded </Link>
        </Text>
      );
      break;
    default:
      content = null;
  }

  return content;
};

const howLongAgo = atime => {
  const byTime = [365 * 24 * 60 * 60 * 1000, 24 * 60 * 60 * 1000, 60 * 60 * 1000, 60 * 1000, 1000];
  const unit = ['year', 'day', 'h', 'min', 'sec'];

  let ct = new Date().getTime() - atime.getTime();
  if (ct < 0) {
    return '';
  }

  let sb = [];
  for (let i = 0; i < byTime.length; i++) {
    if (ct < byTime[i]) {
      continue;
    }
    let temp = Math.floor(ct / byTime[i]);
    ct = ct % byTime[i];
    if (temp > 0) {
      sb.push(temp + unit[i]);
    }
    if (sb.length >= 1) {
      break;
    }
  }
  return <Text type="secondary">{sb.join('')} ago</Text>;
};

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Content',
    dataIndex: 'content',
    hideInSearch: true,
    render: (content, notification) => renderContent(notification),
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
];

export { columns, renderContent, howLongAgo };
