import React, { useEffect } from 'react';
import LoginForm from './Form';
import Logo from './Logo';

import styles from './index.module.css';

const LoginPage = ({ renderLogo = () => <Logo /> }) => {
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        requestAnimationFrame(() => resize());
      },
      { passive: true }
    );
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    let fontSize = document.documentElement.clientWidth / 19.2;
    if (fontSize <= 12) {
      fontSize = 12;
    }
    document.documentElement.style.fontSize = fontSize + 'px';
  };

  return (
    <div className={styles.layout}>
      {renderLogo && renderLogo()}
      <LoginForm />
    </div>
  );
};

export default LoginPage;
