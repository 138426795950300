import request from '@/utils/request';
import qs from 'qs';

export async function get(param) {
  return request(`/settings${qs.stringify(param, { addQueryPrefix: true })}`);
}

export async function updateList(payload) {
  return request(`/settings`, {
    method: 'PATCH',
    body: payload,
  });
}
