import { PlusOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { ModalForm, ProFormField, ProFormUploadDragger } from '@ant-design/pro-form';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getScheduleList, scheduleCSV } from '@/services';
import { PageContainer } from '@ant-design/pro-layout';
import * as FragmentAPI from '@/services/fragmentAPI';
import { VendorType } from '@/constants/vendor.type';
import { Button, message, notification } from 'antd';
import ToursSelect from '@/components/TourSelect';
import ProTable from '@ant-design/pro-table';
import { columns } from './schedule.columns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ScheduledTable = ({ socket }) => {
  const actionRef = useRef();
  const tours = useSelector(state => state.app.tours);
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));
  const [tour, setTour] = useState(null);

  const [showImportForm, setShowImportForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const handleSearch = useCallback(
    async (params, sorters, filters) => {
      return getScheduleList({ ...params, tourId: tour?.catalogId, vendorId: activeVendorId }, sorters, filters);
    },
    [tour, activeVendorId]
  );

  const handleDownloadCsv = useCallback(async () => {
    if (!selectedAll && (!selectedKeys || selectedKeys.length === 0)) {
      notification.warning({ message: 'no select rows' });
    } else {
      await scheduleCSV({ all: selectedAll, idList: selectedKeys });
    }
  }, [selectedAll, selectedKeys]);

  const rowSelection = {
    selectedKeys,
    onChange: keys => {
      setSelectedKeys(keys);
    },
    onSelectAll: selected => {
      if (selected) {
        setSelectedAll(true);
      } else {
        setSelectedKeys([]);
        setSelectedAll(false);
      }
    },
  };

  const importSubmit = async values => {
    const hide = message.loading('processing');
    const body = new FormData();
    values.files.forEach(f => {
      body.append('files', f.originFileObj);
    });
    const res = await FragmentAPI.importJobs(body);
    hide();
    if (res && res.message) {
      setErrorMessage(res.message);
    } else {
      setShowImportForm(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  };

  useEffect(() => {
    setTour(null);
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [activeVendorId]);

  useEffect(() => {
    if (socket && socket.active) {
      socket.emit('subscribe', {
        schedules: {
          page: 1,
          size: 10,
        },
      });
      socket.on('data:schedules', data => {
        if (actionRef.current) {
          actionRef.current.reload();
        }
      });
    }

    return () => {
      if (socket && socket.active) {
        socket.emit('subscribe', {
          schedules: null,
        });
        socket.off('data:schedules');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, activeVendorId]);

  return (
    <PageContainer>
      <ProTable
        actionRef={actionRef}
        rowKey={record => record.id}
        headerTitle="Ticket Purchase Schedules"
        style={{ minWidth: 1000 }}
        columns={columns.concat([
          {
            title: 'Tour',
            key: 'tourId',
            hideInTable: true,
            renderFormItem: () => <ToursSelect tours={tours} setTour={setTour}></ToursSelect>,
          },
        ])}
        request={handleSearch}
        columnsState={{
          persistenceKey: 'pro-table-singe-schedule-list',
          persistenceType: 'localStorage',
        }}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        options={false}
        search={{}}
        toolBarRender={() => [
          <Link to={`/schedule?fromDate=${moment().format('YYYY-MM-DD')}`}>
            <Button type="link" icon={<PlusOutlined />}></Button>
          </Link>,
          <Button
            type="link"
            icon={<UploadOutlined />}
            onClick={() => setShowImportForm(true)}
            disabled={activeVendor && activeVendor.name !== VendorType.SnApp}
          />,
          <Button type="link" icon={<DownloadOutlined />} onClick={() => handleDownloadCsv()} />,
        ]}
        rowSelection={rowSelection}
      />
      {showImportForm && (
        <ModalForm
          title="Import Schedule"
          width="400px"
          visible={showImportForm}
          onVisibleChange={visible => {
            setShowImportForm(visible);
            if (!visible) {
              setErrorMessage(null);
            }
          }}
          onFinish={importSubmit}
        >
          <ProFormField>
            <Button
              key="button"
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => window.open(process.env.REACT_APP_API_BASE_URL + '/fragments/import/template', '_blank')}
            >
              Download Template
            </Button>
          </ProFormField>

          <ProFormUploadDragger
            name="files"
            title="Click or drag files to this area to upload"
            description="Support single or batch upload"
            max={10}
            rules={[
              {
                required: false,
              },
            ]}
            accept=".csv"
            fieldProps={{
              beforeUpload: file => {
                return false;
              },
            }}
          ></ProFormUploadDragger>
          {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        </ModalForm>
      )}
    </PageContainer>
  );
};

export default ScheduledTable;
