import { Typography, Badge, Space } from 'antd';
import { naturalNumber } from '@/utils/number';
import { VendorType } from '@/constants';
import { Link } from 'react-router-dom';
import moment from 'moment';
const { Text } = Typography;

export const getColumns = (schedule, tour, accounts, activeVendor, authContext) => [
  {
    title: 'From Time',
    dataIndex: 'fromTime',
    render: time => {
      const fromTime = moment(time, 'HH:mm:ss').format('HH:mm');
      const type = schedule?.tour?.timePoints?.find(o => o.timePoint.substring(0, 5) === fromTime)?.type || [];
      return fromTime + ' ' + type.join(',');
    },
    defaultSortOrder: 'ascend',
    sorter: true,
    formItemProps: (form, { rowIndex }) => {
      return {
        rules: [{ required: true, message: 'Time can not be empty' }],
      };
    },
    request: async () =>
      schedule?.tour?.timePoints?.map((item, index) => ({
        label: item.timePoint.substring(0, 5) + ' ' + item.type.join(','),
        value: item.timePoint.substring(0, 5),
      })),
  },
  {
    title: 'To Time',
    dataIndex: 'toTime',
    render: time => {
      return time;
    },
    defaultSortOrder: 'ascend',
    sorter: true,
  },
  {
    title: 'Account',
    dataIndex: 'account',
    render: account => account?.username || 'Auto select',
    request: async () =>
      [
        {
          label: 'Auto select',
          value: 'automation',
        },
      ].concat(
        accounts
          .filter(a => a.active)
          .map(item => ({
            label: item.username,
            value: item.id,
          }))
      ),
  },
  {
    title: 'Client Tickets',
    dataIndex: 'clientTicketsNumber',
    render: (text, record) => `${record.clientTicketsNumber}T`,
    formItemProps: (form, { rowIndex }) => {
      return {
        rules: [{ required: true, message: 'Client Tickets can not be empty' }],
      };
    },
    request: async () =>
      naturalNumber(tour ? tour.maxTicketsPerAccount : 0, 1).map(item => ({
        label: item,
        value: item,
      })),
  },
  {
    title: 'Compete',
    dataIndex: 'compete',
    request: async () => [
      { label: 'true', value: true },
      { label: 'false', value: false },
    ],
  },
  {
    title: 'Dry Run',
    dataIndex: 'dryRun',
    request: async () => [
      { label: 'true', value: true },
      { label: 'false', value: false },
    ],
  },
  {
    title: 'Jobs Count',
    dataIndex: 'jobsCount',
    width: 70,
    render: jobsCount => jobsCount,
    editable: (text, record, index) => {
      return !(activeVendor && activeVendor.name === VendorType.Colosseum) || authContext.isRole('admin');
    },
    request: async () =>
      naturalNumber(100, 1).map(item => ({
        label: item,
        value: item,
      })),
  },
  {
    title: 'Action',
    valueType: 'option',
    render: (text, record, _, action) => {
      return <Space></Space>;
    },
  },
];

export const getModalColumns = (schedule, tour, accounts, guides, handleCancelJob) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 90,
    render: id => (
      <Link to={`/job/${id}`}>
        <Text style={{ width: 64 }} ellipsis={{ tooltip: id }} type="warning">
          {id}
        </Text>
      </Link>
    ),
    editable: (text, record, index) => {
      return false;
    },
  },
  {
    title: 'Time',
    dataIndex: 'fromTime',
    render: (time, record) => {
      const fromTime = moment(record.fromTime, 'HH:mm:ss').format('HH:mm');
      if (record.toTime) {
        const toTime = moment(record.toTime, 'HH:mm:ss').format('HH:mm');
        return `${fromTime} - ${toTime}`;
      }
      const type = schedule?.tour?.timePoints?.find(o => o.timePoint.substring(0, 5) === fromTime)?.type || [];
      return fromTime + ' ' + type.join(',');
    },
    defaultSortOrder: 'ascend',
    sorter: true,
    formItemProps: (form, { rowIndex }) => {
      return {
        rules: [{ required: true, message: 'Time can not be empty' }],
      };
    },
    request: async () =>
      schedule?.tour?.timePoints?.map((item, index) => ({
        label: item.timePoint.substring(0, 5) + ' ' + item.type.join(','),
        value: item.timePoint.substring(0, 5),
      })),
  },
  {
    title: 'Account',
    dataIndex: 'account',
    render: account => account?.username || 'Auto select',
    request: async () =>
      [
        {
          label: 'Auto select',
          value: 'automation',
        },
      ].concat(
        accounts
          .filter(a => a.active)
          .map(item => ({
            label: item.username,
            value: item.id,
          }))
      ),
  },
  {
    title: 'Client Tickets',
    dataIndex: 'clientTicketsNumber',
    render: (text, record) => `${record.clientTicketsNumber}T`,
    formItemProps: (form, { rowIndex }) => {
      return {
        rules: [{ required: true, message: 'Client Tickets can not be empty' }],
      };
    },
    request: async () =>
      naturalNumber(tour ? tour.maxTicketsPerAccount : 0, 1).map(item => ({
        label: item,
        value: item,
      })),
  },
  {
    title: 'Compete',
    dataIndex: 'compete',
    render: compete => (compete ? 'True' : 'False'),
  },
  {
    title: 'Dry Run',
    dataIndex: 'dryRun',
    render: dryRun => (dryRun ? 'True' : 'False'),
  },
  {
    title: 'Status',
    dataIndex: 'purchaseStatus',
    defaultFilteredValue: ['Pending Purchase', 'Checking', 'Holding', 'Purchasing', 'Purchased'],
    filters: [
      {
        text: 'Pending Purchase',
        value: 'Pending Purchase',
      },
      {
        text: 'Checking',
        value: 'Checking',
      },
      {
        text: 'Holding',
        value: 'Holding',
      },
      {
        text: 'Purchasing',
        value: 'Purchasing',
      },
      {
        text: 'Paying',
        value: 'Paying',
      },
      {
        text: 'Purchased',
        value: 'Purchased',
      },
      {
        text: 'Failed Purchase',
        value: 'Failed Purchase',
      },
      {
        text: 'Canceled',
        value: 'Canceled',
      },
    ],
    render: status => {
      let badge;
      switch (status) {
        case 'Pending Purchase':
          badge = <Badge status="default" text={status} />;
          break;
        case 'Purchasing':
          badge = <Badge status="processing" text={status} />;
          break;
        case 'Checking':
          badge = <Badge status="processing" text={status} />;
          break;
        case 'Holding':
          badge = <Badge status="default" text={status} />;
          break;
        case 'Paying':
          badge = <Badge status="processing" text={status} />;
          break;
        case 'Canceled':
          badge = <Badge status="warning" text={status} />;
          break;
        case 'Failed Purchase':
          badge = <Badge status="error" text={status} />;
          break;
        case 'Purchased':
          badge = <Badge status="success" text={status} />;
          break;
        default:
          badge = <Badge status="default" text={status} />;
          break;
      }
      return badge;
    },
    editable: (text, record, index) => {
      return false;
    },
  },
  {
    title: 'Fail Reason',
    dataIndex: 'failReason',
    width: 200,
    render: failReason =>
      failReason ? (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: failReason }} type="danger">
          {failReason}
        </Text>
      ) : null,
  },
  {
    title: 'Last Snapshot',
    dataIndex: 'lastSnapshot',
    render: lastSnapshot => {
      return lastSnapshot && lastSnapshot.length > 5 ? (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <a
          onClick={() => {
            window.open(`${process.env.REACT_APP_API_BASE_URL}/aws/view${lastSnapshot}`);
          }}
        >
          Open
        </a>
      ) : null;
    },
  },
  {
    title: 'Job Index',
    dataIndex: 'jobIndex',
  },
  {
    title: 'Action',
    valueType: 'option',
    render: (text, record, _, action) => {
      return (
        <Space>
          {['Pending Purchase', 'Purchasing', 'Checking', 'Holding'].includes(record.purchaseStatus) && (
            <Typography.Link key="cancel" onClick={() => handleCancelJob(record)}>
              Cancel
            </Typography.Link>
          )}
        </Space>
      );
    },
  },
];

export const getGroupColumns = (schedule, openDetailModal) => [
  {
    title: 'Time',
    dataIndex: 'fromTime',
    render: (time, record) => {
      const fromTime = moment(time, 'HH:mm:ss').format('HH:mm');
      if (record.toTime) {
        const toTime = moment(record.toTime, 'HH:mm:ss').format('HH:mm');
        return `${fromTime} - ${toTime}`;
      }
      const type = schedule?.tour?.timePoints?.find(o => o.timePoint.substring(0, 5) === fromTime)?.type || [];
      return fromTime + ' ' + type.join(',');
    },
    defaultSortOrder: 'ascend',
    sorter: true,
  },
  {
    title: 'Client Tickets',
    dataIndex: 'clientTicketsNumber',
    render: (text, record) => `${record.clientTicketsNumber}T`,
  },
  {
    title: 'Purchased',
    dataIndex: 'purchasedNumber',
    render: purchasedNumber => `${purchasedNumber}T`,
  },
  {
    title: 'Compete',
    dataIndex: 'compete',
    render: compete => (compete ? 'True' : 'False'),
  },
  {
    title: 'Dry Run',
    dataIndex: 'dryRun',
    render: dryRun => (dryRun ? 'True' : 'False'),
  },
  {
    title: 'Jobs Count',
    dataIndex: 'jobsCount',
    render: jobsCount => jobsCount,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      if (!status) {
        return '';
      }
      if (status.indexOf('Paying') >= 0) {
        return <Badge status="processing" text={'Paying'} />;
      } else if (status.indexOf('Purchasing') >= 0) {
        return <Badge status="processing" text={'Purchasing'} />;
      } else if (status.indexOf('Holding') >= 0) {
        return <Badge status="default" text={'Holding'} />;
      } else if (status.indexOf('Checking') >= 0) {
        return <Badge status="processing" text={'Checking'} />;
      } else if (status.indexOf('Pending Purchase') >= 0) {
        return <Badge status="default" text={'Pending Purchase'} />;
      } else if (status.indexOf('Purchased') >= 0) {
        return <Badge status="success" text={'Purchased'} />;
      } else if (status.indexOf('Failed Purchase') >= 0) {
        return <Badge status="error" text={'Failed Purchase'} />;
      } else if (status.indexOf('Canceled') >= 0) {
        return <Badge status="warning" text={'Canceled'} />;
      }
      return <Badge status="success" text={status} />;
    },
  },
  {
    title: 'Action',
    valueType: 'option',
    render: (text, record, _, action) => {
      return <Space>{<Typography.Link onClick={() => openDetailModal(record)}>Details</Typography.Link>}</Space>;
    },
  },
];
