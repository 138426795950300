import { Badge, Descriptions, Modal, Tag } from 'antd';
import React from 'react';

const formatJSON = text => {
  if (text) {
    try {
      text = text.replace(/\\\\/g, '');
      text = JSON.stringify(JSON.parse(text), null, 4);
      return text.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp');
    } catch (err) {
      return text;
    }
  }
};

const DetailModal = ({ visible, handleModalCancel, log }) => {
  return (
    <Modal title="Log Detail" open={visible} width={1024} onCancel={() => handleModalCancel()} footer={null}>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Request Account">{log.traceAccount}</Descriptions.Item>
        <Descriptions.Item label="Request URL ">
          <Tag color="green">{log.reqMethod}</Tag> {log.reqUrl}
        </Descriptions.Item>
        <Descriptions.Item label="Request Header">
          <div dangerouslySetInnerHTML={{ __html: formatJSON(log.reqHeader) }}></div>
        </Descriptions.Item>
        <Descriptions.Item label="Request Body">
          <div dangerouslySetInnerHTML={{ __html: formatJSON(log.reqBody) }}></div>
        </Descriptions.Item>
        <Descriptions.Item label="Response Status">
          {log.status < 400 || log.respStatus < 400 ? (
            <Badge status="success" text={`${log.status} ok`} />
          ) : (
            <Badge status="error" text={`${log.status} ${log.errorMessage}`} />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Response Header">
          <div dangerouslySetInnerHTML={{ __html: formatJSON(log.respHeader) }}></div>
        </Descriptions.Item>
        <Descriptions.Item label="Response Body">
          <div dangerouslySetInnerHTML={{ __html: formatJSON(log.respBody) }}></div>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default DetailModal;
