import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import styles from './Form.module.css';

const FormItem = Form.Item;
const ForgetPasswordForm = ({ onFinish }) => {
  const handleFormFinish = values => {
    if (onFinish) {
      onFinish(values);
    }
  };

  return (
    <div className={styles.form}>
      <h2>Forgot Password</h2>
      <p className={styles['signup-message']}>Please enter your email, we will send the reset password code</p>
      <Form onFinish={handleFormFinish} layout={'vertical'}>
        <FormItem
          label={'Your Email'}
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="Enter your email" />
        </FormItem>
        <Form.Item>
          <Link className={styles.forgetPassword} to="/login">
            Back to sign in page
          </Link>
        </Form.Item>
        <FormItem style={{ marginTop: '0.4rem' }}>
          <Button htmlType="submit" type="primary" size="large" block className={styles['btn-login']}>
            Submit
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default ForgetPasswordForm;
