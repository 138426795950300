export const getCronOptions = () => {
  return [
    {
      label: 'EVERY_SECOND',
      value: '* * * * * *',
    },
    {
      label: 'EVERY_2_SECONDS',
      value: '*/2 * * * * *',
    },
    {
      label: 'EVERY_3_SECONDS',
      value: '*/3 * * * * *',
    },
    {
      label: 'EVERY_4_SECONDS',
      value: '*/4 * * * * *',
    },
    {
      label: 'EVERY_5_SECONDS',
      value: '*/5 * * * * *',
    },
    {
      label: 'EVERY_10_SECONDS',
      value: '*/10 * * * * *',
    },
    {
      label: 'EVERY_30_SECONDS',
      value: '*/30 * * * * *',
    },
    {
      label: 'EVERY_MINUTE',
      value: '*/1 * * * *',
    },
    {
      label: 'EVERY_5_MINUTES',
      value: '0 */5 * * * *',
    },
    {
      label: 'EVERY_10_MINUTES',
      value: '0 */10 * * * *',
    },
    {
      label: 'EVERY_30_MINUTES',
      value: '0 */30 * * * *',
    },
    {
      label: 'EVERY_HOUR',
      value: '0 0-23/1 * * *',
    },
    {
      label: 'EVERY_2_HOURS',
      value: '0 0-23/2 * * *',
    },
    {
      label: 'EVERY_3_HOURS',
      value: '0 0-23/3 * * *',
    },
    {
      label: 'EVERY_4_HOURS',
      value: '0 0-23/4 * * *',
    },
    {
      label: 'EVERY_5_HOURS',
      value: '0 0-23/5 * * *',
    },
    {
      label: 'EVERY_6_HOURS',
      value: '0 0-23/6 * * *',
    },
    {
      label: 'EVERY_7_HOURS',
      value: '0 0-23/7 * * *',
    },
    {
      label: 'EVERY_8_HOURS',
      value: '0 0-23/8 * * *',
    },
    {
      label: 'EVERY_9_HOURS',
      value: '0 0-23/9 * * *',
    },
    {
      label: 'EVERY_10_HOURS',
      value: '0 0-23/10 * * *',
    },
    {
      label: 'EVERY_11_HOURS',
      value: '0 0-23/11 * * *',
    },
    {
      label: 'EVERY_12_HOURS',
      value: '0 0-23/12 * * *',
    },
    {
      label: 'EVERY_DAY_AT_1AM',
      value: '0 01 * * *',
    },
    {
      label: 'EVERY_DAY_AT_2AM',
      value: '0 02 * * *',
    },
    {
      label: 'EVERY_DAY_AT_3AM',
      value: '0 03 * * *',
    },
    {
      label: 'EVERY_DAY_AT_4AM',
      value: '0 04 * * *',
    },
    {
      label: 'EVERY_DAY_AT_5AM',
      value: '0 05 * * *',
    },
    {
      label: 'EVERY_DAY_AT_6AM',
      value: '0 06 * * *',
    },
    {
      label: 'EVERY_DAY_AT_7AM',
      value: '0 07 * * *',
    },
    {
      label: 'EVERY_DAY_AT_8AM',
      value: '0 08 * * *',
    },
    {
      label: 'EVERY_DAY_AT_9AM',
      value: '0 09 * * *',
    },
    {
      label: 'EVERY_DAY_AT_10AM',
      value: '0 10 * * *',
    },
    {
      label: 'EVERY_DAY_AT_11AM',
      value: '0 11 * * *',
    },
    {
      label: 'EVERY_DAY_AT_NOON',
      value: '0 12 * * *',
    },
    {
      label: 'EVERY_DAY_AT_1PM',
      value: '0 13 * * *',
    },
    {
      label: 'EVERY_DAY_AT_2PM',
      value: '0 14 * * *',
    },
    {
      label: 'EVERY_DAY_AT_3PM',
      value: '0 15 * * *',
    },
    {
      label: 'EVERY_DAY_AT_4PM',
      value: '0 16 * * *',
    },
    {
      label: 'EVERY_DAY_AT_5PM',
      value: '0 17 * * *',
    },
    {
      label: 'EVERY_DAY_AT_6PM',
      value: '0 18 * * *',
    },
    {
      label: 'EVERY_DAY_AT_7PM',
      value: '0 19 * * *',
    },
    {
      label: 'EVERY_DAY_AT_8PM',
      value: '0 20 * * *',
    },
    {
      label: 'EVERY_DAY_AT_9PM',
      value: '0 21 * * *',
    },
    {
      label: 'EVERY_DAY_AT_10PM',
      value: '0 22 * * *',
    },
    {
      label: 'EVERY_DAY_AT_11PM',
      value: '0 23 * * *',
    },
    {
      label: 'EVERY_DAY_AT_MIDNIGHT',
      value: '0 0 * * *',
    },
    {
      label: 'EVERY_WEEK',
      value: '0 0 * * 0',
    },
    {
      label: 'EVERY_WEEKDAY',
      value: '0 0 * * 1-5',
    },
    {
      label: 'EVERY_WEEKEND',
      value: '0 0 * * 6,0',
    },
    {
      label: 'EVERY_1ST_DAY_OF_MONTH_AT_MIDNIGHT',
      value: '0 0 1 * *',
    },
    {
      label: 'EVERY_1ST_DAY_OF_MONTH_AT_NOON',
      value: '0 12 1 * *',
    },
    {
      label: 'EVERY_2ND_HOUR',
      value: '0 */2 * * *',
    },
    {
      label: 'EVERY_2ND_HOUR_FROM_1AM_THROUGH_11PM',
      value: '0 1-23/2 * * *',
    },
    {
      label: 'EVERY_2ND_MONTH',
      value: '0 0 1 */2 *',
    },
    {
      label: 'EVERY_QUARTER',
      value: '0 0 1 */3 *',
    },
    {
      label: 'EVERY_6_MONTHS',
      value: '0 0 1 */6 *',
    },
    {
      label: 'EVERY_YEAR',
      value: '0 0 1 1 *',
    },
    {
      label: 'EVERY_30_MINUTES_BETWEEN_9AM_AND_5PM',
      value: '0 */30 9-17 * * *',
    },
    {
      label: 'EVERY_30_MINUTES_BETWEEN_9AM_AND_6PM',
      value: '0 */30 9-18 * * *',
    },
    {
      label: 'EVERY_30_MINUTES_BETWEEN_10AM_AND_7PM',
      value: '0 */30 10-19 * * *',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_1AM',
      value: '0 0 01 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_2AM',
      value: '0 0 02 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_3AM',
      value: '0 0 03 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_4AM',
      value: '0 0 04 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_5AM',
      value: '0 0 05 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_6AM',
      value: '0 0 06 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_7AM',
      value: '0 0 07 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_8AM',
      value: '0 0 08 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_9AM',
      value: '0 0 09 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_09_30AM',
      value: '0 30 09 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_10AM',
      value: '0 0 10 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_11AM',
      value: '0 0 11 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_11_30AM',
      value: '0 30 11 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_12PM',
      value: '0 0 12 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_1PM',
      value: '0 0 13 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_2PM',
      value: '0 0 14 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_3PM',
      value: '0 0 15 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_4PM',
      value: '0 0 16 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_5PM',
      value: '0 0 17 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_6PM',
      value: '0 0 18 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_7PM',
      value: '0 0 19 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_8PM',
      value: '0 0 20 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_9PM',
      value: '0 0 21 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_10PM',
      value: '0 0 22 * * 1-5',
    },
    {
      label: 'MONDAY_TO_FRIDAY_AT_11PM',
      value: '0 0 23 * * 1-5',
    },
  ];
};
