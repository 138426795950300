import React from 'react';
import { Link } from 'react-router-dom';
import { FormatDateTime } from '@/components/FormatDateTime';
import { Typography } from 'antd';
import moment from 'moment';
import { ProFormSelect } from '@ant-design/pro-form';

const { Text } = Typography;

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    hideInSearch: true,
    render: value => (
      <Link to={`/job/${value}`}>
        <Text style={{ width: 64 }} ellipsis={{ tooltip: value }} type="warning">
          {value}
        </Text>
      </Link>
    ),
  },
  {
    title: 'Schedule Id',
    dataIndex: 'schedule',
    hideInSearch: true,
    render: value => (
      <Link to={`/schedule?catalogId=${value.tourId}&fromDate=${value.fromDate}`}>
        <Text style={{ width: 64 }} ellipsis={{ tooltip: value.id }} type="warning">
          {value.id}
        </Text>
      </Link>
    ),
  },
  {
    title: 'Vendor',
    dataIndex: ['schedule', 'vendor'],
    hideInSearch: true,
    render: vendor => (vendor ? vendor.name : 'Unknow'),
  },
  {
    title: 'Tour',
    width: 250,
    dataIndex: ['schedule', 'tour', 'catalogName'],
    hideInSearch: true,
    render: value => (
      <Text style={{ width: 220 }} ellipsis={{ tooltip: value }}>
        {value}
      </Text>
    ),
  },
  {
    title: 'Account',
    dataIndex: ['account', 'username'],
    hideInSearch: true,
  },
  {
    title: 'Time',
    dataIndex: 'fromTime',
    hideInSearch: true,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (value, record) =>
      record.toTime
        ? `${record.fromTime.substring(0, 5)} - ${record.toTime.substring(0, 5)}`
        : `${record.fromTime.substring(0, 5)}`,
  },
  {
    title: 'Ticket Date Time',
    dataIndex: 'purchaseTime',
    hideInSearch: true,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (value, record) => (
      <FormatDateTime
        time={`${record.schedule.fromDate} ${record.purchaseTime ? record.purchaseTime : ''}`}
      ></FormatDateTime>
    ),
  },
  {
    title: 'Job Start Time',
    dataIndex: 'tourAvailability',
    hideInSearch: true,
    render: (value, record) => {
      let fromDateTime = moment(`${record.schedule.fromDate} ${record.fromTime}`);
      if (value?.performanceTypeCode === 'DSETT') {
        fromDateTime = fromDateTime.add(-7, 'day');
      } else {
        fromDateTime = fromDateTime.add(-1, 'month');
      }
      return <FormatDateTime time={fromDateTime}></FormatDateTime>;
    },
  },
  {
    title: 'Client Tickets',
    dataIndex: 'clientTicketsNumber',
    hideInSearch: true,
  },
  {
    title: 'Purchase Status',
    dataIndex: 'purchaseStatus',
    hideInSearch: true,
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    hideInSearch: true,
    sorter: true,
    render: value => <FormatDateTime time={value}></FormatDateTime>,
  },
  {
    title: 'Ticket Date',
    key: 'fromDate',
    valueType: 'dateRange',
    hideInTable: true,
  },
  {
    title: 'Status',
    key: 'purchaseStatus',
    hideInTable: true,
    renderFormItem: () => (
      <ProFormSelect
        mode="tags"
        options={[
          { label: 'Pending Purchase', value: 'Pending Purchase' },
          { label: 'Purchasing', value: 'Purchasing' },
          { label: 'Holding', value: 'Holding' },
          { label: 'Checking', value: 'Checking' },
          { label: 'Paying', value: 'Paying' },
          { label: 'Purchased', value: 'Purchased' },
          { label: 'Failed Purchase', value: 'Failed Purchase' },
          { label: 'Canceled', value: 'Canceled' },
        ]}
        width="md"
        name="purchaseStatus"
      />
    ),
  },
];

export default columns;
