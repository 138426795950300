import { ModalForm, ProFormDatePicker, ProFormSelect, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import { saveCard } from '@/services/cardAPI';
import { useState } from 'react';
import { message, Row } from 'antd';
import styles from './index.module.less';
import * as moment from 'moment';

const CardAddForm = ({ showAddForm, setShowAddForm, onFinish }) => {
  const [errorMessage, setErrorMessage] = useState();

  const addSubmit = async values => {
    const hide = message.loading('processing');
    const res = await saveCard({
      ...values,
      cardNumber: values.cardNumber.replaceAll(/\s/g, ''),
    });
    hide();
    if (res && res.id) {
      setErrorMessage(null);
      setShowAddForm(false);
      onFinish();
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    showAddForm && (
      <ModalForm
        title="Add Card"
        width="800px"
        visible={showAddForm}
        onVisibleChange={visible => {
          setShowAddForm(visible);
          if (!visible) {
            setErrorMessage(null);
          }
        }}
        initialValues={{
          isDivvyCard: true,
        }}
        onFinish={addSubmit}
      >
        <Row justify={'space-between'}>
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="name"
            label="Name"
          ></ProFormText>
          <ProFormSelect
            options={[
              {
                value: 'VISA',
                label: 'VISA',
              },
              {
                value: 'MASTERCARD',
                label: 'MASTERCARD',
              },
            ]}
            width="md"
            name="brand"
            label="Brand"
          />
        </Row>
        <Row justify={'space-between'}>
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="holderFirstName"
            label="Holder First Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="holderLastName"
            label="Holder Last Name"
          />
        </Row>
        <Row justify={'start'} className={styles.cardNumber}>
          <ProFormText
            rules={[
              {
                validator: (rule, value) => {
                  if (value) {
                    const formatValue = value.replaceAll(/\s/g, '');
                    const result1 = new RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/gi).test(formatValue);
                    const result2 = new RegExp(
                      /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/gi
                    ).test(formatValue);
                    if (result1 || result2) {
                      return Promise.resolve();
                    }
                    return Promise.reject(`Card number format error`);
                  } else {
                    return Promise.reject('Please enter card number');
                  }
                },
              },
            ]}
            width={'lg'}
            name="cardNumber"
            label="Card Number"
          />
        </Row>
        <Row justify={'space-between'}>
          <ProFormText
            rules={[
              {
                validator: (rule, value) => {
                  if (value) {
                    const result = new RegExp(/^\d{3}$/gi).test(value);
                    if (result) {
                      return Promise.resolve();
                    }
                    return Promise.reject(`CVV format error`);
                  } else {
                    return Promise.reject('Please enter CVV');
                  }
                },
              },
            ]}
            width="md"
            name="cvv"
            label="CVV"
          />
          <ProFormDatePicker
            rules={[
              {
                validator: (rule, value) => {
                  if (value) {
                    if (moment(value).isBefore(moment())) {
                      return Promise.reject(`Expire date has passed`);
                    }
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Please enter expire date');
                  }
                },
              },
            ]}
            width="md"
            name="expireDate"
            label="Expire Date"
          ></ProFormDatePicker>
        </Row>
        <ProFormSwitch
          rules={[
            {
              required: true,
            },
          ]}
          width="md"
          name="isDivvyCard"
          label="Is Divvy Card"
        ></ProFormSwitch>
        {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
      </ModalForm>
    )
  );
};

export default CardAddForm;
