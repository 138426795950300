import { Col, Row, Select, Calendar, Space } from 'antd';
import { naturalNumber } from '@/utils/number';
import moment from 'moment';

const CustomCalendar = ({ value, onChange }) => {
  const handleDisableDate = current => {
    return moment().startOf('day').isAfter(current);
  };

  return (
    <Calendar
      value={value}
      onChange={onChange}
      fullscreen={false}
      disabledDate={handleDisableDate}
      headerRender={({ value, onChange }) => {
        const yearArr = naturalNumber(5);
        const currentYear = moment().year();
        const yearOptions = yearArr.map(number => (
          <Select.Option className="year-item" key={`${currentYear + number}`}>
            {currentYear + number}
          </Select.Option>
        ));

        const monthArr = naturalNumber(12);
        const monthOptions = monthArr.map(number => {
          const text = moment().startOf('year').add(number, 'month').format('MMM');
          return (
            <Select.Option className="month-item" value={number} key={text}>
              {text}
            </Select.Option>
          );
        });

        return (
          <Row style={{ marginBottom: 8 }}>
            <Col offset={14} span={6}>
              <Space>
                <Select
                  size="small"
                  dropdownMatchSelectWidth={false}
                  value={value.year()}
                  onChange={selectedYear => {
                    const newValue = value.clone();
                    newValue.year(parseInt(selectedYear, 10));
                    onChange(newValue);
                  }}
                >
                  {yearOptions}
                </Select>
                <Select
                  size="small"
                  dropdownMatchSelectWidth={false}
                  value={value.month()}
                  onChange={selectedMonth => {
                    const newValue = value.clone();
                    newValue.month(parseInt(selectedMonth, 10));
                    if (newValue.isBefore(moment())) {
                      onChange(moment());
                    } else {
                      onChange(newValue);
                    }
                  }}
                >
                  {monthOptions}
                </Select>
              </Space>
            </Col>
          </Row>
        );
      }}
    />
  );
};

export default CustomCalendar;
