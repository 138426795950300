import request from '@/utils/request';
import qs from 'qs';

export async function page(payload) {
  return request(`/log${qs.stringify(payload, { addQueryPrefix: true })}`);
}

export async function getByTraceId(traceId) {
  return request(`/log/trace/${traceId}`);
}

export async function getById(id, createdAt) {
  return request(`/log/${id}${qs.stringify({ createdAt }, { addQueryPrefix: true })}`);
}
