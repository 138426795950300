import React from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveVendorId } from '@/store/AppReducer';
import { useSearchParams } from 'react-router-dom';
const { Option } = Select;

const Vendor = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams({});
  const vendors = useSelector(state => state.app.vendors);
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const onChange = selected => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      vendorId: activeVendorId,
    });
    dispatch(setActiveVendorId(selected));
  };

  return (
    <Select
      style={{ width: 120 }}
      loading={!vendors || vendors.length === 0}
      value={vendors && vendors.length ? activeVendorId || searchParams.get('vendorId') : null}
      onChange={onChange}
    >
      {vendors &&
        vendors.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
    </Select>
  );
};

export default Vendor;
