import qs from 'qs';
import request from '@/utils/request';

export async function page(payload) {
  const res = await request(`/availabilities${qs.stringify(payload, { addQueryPrefix: true })}`);
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}
