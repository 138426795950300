import { useCallback, useEffect, useRef, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { Button, Modal } from 'antd';
import * as FragmentAPI from '@/services/fragmentAPI';
import { getModalColumns } from './index.column';
import { useSelector } from 'react-redux';

const JobDetailsModal = ({
  tour,
  accounts,
  guides,
  visible,
  setVisible,
  schedule,
  groups,
  fromTime,
  toTime,
  compete,
  dryRun,
  clientTicketsNumber,
}) => {
  const activeVendorId = useSelector(state => state.app.activeVendorId);

  const actionRef = useRef();
  const [loading, setLoading] = useState(false);
  const [cancelableIds, setCancelableIds] = useState([]);

  const cancelAll = useCallback(
    async ids => {
      await FragmentAPI.cancelBatch(ids);
      if (actionRef.current && visible) {
        actionRef.current.reload();
      }
    },
    [visible]
  );

  const fetchFragments = useCallback(
    async (params, filters, sorter) => {
      const { pageSize, current, ...otherParams } = params;
      setLoading(true);
      const result = await FragmentAPI.page({
        size: pageSize,
        page: current,
        ...otherParams,
        ...filters,
        sorter,
        scheduleId: schedule?.id,
        fromTime,
        toTime,
        compete,
        dryRun,
        clientTicketsNumber,
        vendorId: activeVendorId,
      });
      setLoading(false);
      setCancelableIds(
        result.data
          .filter(record => ['Pending Purchase', 'Purchasing', 'Checking', 'Holding'].includes(record.purchaseStatus))
          .map(f => f.id)
      );
      return result;
    },
    [setCancelableIds, activeVendorId, clientTicketsNumber, compete, dryRun, fromTime, schedule, toTime]
  );

  useEffect(() => {
    if (actionRef.current && visible) {
      actionRef.current.reload();
    }
  }, [fromTime, clientTicketsNumber, groups, visible]);

  const handleCancelJob = async record => {
    await FragmentAPI.cancel(record.id);
    if (actionRef.current) {
      actionRef.current.reload();
    }
  };

  return (
    <Modal
      width={1800}
      title="Job Detail"
      open={visible}
      footer={null}
      closable={true}
      onCancel={() => setVisible(false)}
    >
      <ProTable
        loading={loading}
        actionRef={actionRef}
        columns={getModalColumns(schedule, tour, accounts, guides, handleCancelJob)}
        request={fetchFragments}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={false}
        pagination={{
          pageSize: 10,
        }}
        options={false}
        toolBarRender={() => [
          <Button
            key="button"
            disabled={!cancelableIds || cancelableIds.length === 0}
            onClick={() => cancelAll(cancelableIds)}
          >
            Cancel All
          </Button>,
        ]}
      ></ProTable>
    </Modal>
  );
};

export default JobDetailsModal;
