import React, { useCallback, useEffect, useRef, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import * as FragmentAPI from '@/services/fragmentAPI';
import ToursSelect from '@/components/TourSelect';
import AccountSelect from '@/components/AccountSelect';
import { useSelector } from 'react-redux';
import columns from './job.columns';

const JobList = () => {
  const ref = useRef();

  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const tours = useSelector(state => state.app.tours);

  const [tour, setTour] = useState(null);

  const handleSearch = useCallback(
    async (params, sorters, filter) => {
      const { pageSize, current, ...otherParams } = params;
      return await FragmentAPI.page({
        size: pageSize,
        page: current,
        ...otherParams,
        ...filter,
        sorters,
        vendorId: activeVendorId,
        tourId: tour?.catalogId,
      });
    },
    [tour, activeVendorId]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.reload();
    }
  }, [activeVendorId]);

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat([
          {
            title: 'Tour',
            key: 'tourId',
            hideInTable: true,
            renderFormItem: () => <ToursSelect tours={tours} setTour={setTour}></ToursSelect>,
          },
          {
            title: 'Account',
            key: 'accountId',
            hideInTable: true,
            renderFormItem: () => <AccountSelect></AccountSelect>,
          },
          {
            title: 'Fail Reason',
            key: 'failReason',
            hideInTable: true,
          },
        ])}
        request={handleSearch}
        columnsState={{
          persistenceKey: 'pro-table-singe-job-list',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={{
          collapsed: false,
          collapseRender: false,
        }}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        headerTitle="Job List"
        options={false}
        actionRef={ref}
      />
    </PageContainer>
  );
};

export default JobList;
