import { searchSchedule, updateSchedule, saveSchedule } from '@/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { findTourValue, findTour } from '@/utils/tour';
import ScheduleForm from './components/ScheduleForm';
import React, { useState, useEffect } from 'react';
import * as ToursAPI from '@/services/toursAPI';
import { Form, message, Card } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ScheduleAdd = () => {
  const navigation = useNavigate();
  const tours = useSelector(state => state.app.tours);
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));
  const activeVendorId = useSelector(state => state.app.activeVendorId);

  const [originVendorId, setOriginVendorId] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams({});
  const catalogId = searchParams.get('catalogId');
  const fromDate = searchParams.get('fromDate');

  const [form] = Form.useForm();
  const [schedule, setSchedule] = useState(null);

  const fetchSchedule = async (catalogId, date) => {
    try {
      const result = await searchSchedule({
        catalogId: catalogId,
        fromDate: typeof date === 'string' ? date : date.format('YYYY-MM-DD'),
      });
      if (result.id) {
        form.setFieldsValue({
          tour: findTourValue(result.tourId, tours),
          date: moment(result.fromDate),
          maxTicketsPerDay: result.maxTicketsPerDay,
          retry: result.retry,
        });
        setSchedule(result);
      } else {
        const tour = await ToursAPI.getTour(catalogId);
        form.setFieldsValue({
          tour: findTourValue(catalogId, tours),
          date: moment(fromDate),
          maxTicketsPerDay: 1,
          retry: 0,
        });
        setSchedule({ tour });
      }
      return result;
    } catch (error) {
      console.log('fetch schedule error', error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      tour: null,
    });
  }, [tours, form]);

  useEffect(() => {
    if (originVendorId) {
      navigation('/');
    } else {
      setOriginVendorId(activeVendorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  useEffect(() => {
    if (tours.length > 0) {
      form.setFieldsValue({
        date: moment(fromDate),
      });

      if (catalogId && fromDate) {
        fetchSchedule(catalogId, fromDate);
      } else {
        setSchedule(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, tours]);

  const handleValuesChange = async changedValues => {
    if (changedValues['tour'] || changedValues['date']) {
      const { tour, date } = form.getFieldsValue(['tour', 'date']);
      if (tour && date) {
        const tourObj = findTour(tour, tours);
        setSearchParams({
          catalogId: tourObj.catalogId,
          fromDate: date.format('YYYY-MM-DD'),
        });
      }
    }
  };

  const handleSubmit = async values => {
    const tour = findTour(values.tour, tours);
    if (schedule && schedule.id) {
      await updateSchedule(schedule.id, {
        maxTicketsPerDay: values.maxTicketsPerDay,
        retry: values.retry,
      });
    } else {
      await saveSchedule({
        tourId: tour.catalogId,
        eventId: tour.entityId,
        tourName: tour.catalogName,
        maxTicketsPerDay: values.maxTicketsPerDay,
        fromDate: moment(values.date).format('YYYY-MM-DD'),
        retry: values.retry,
        vendorType: activeVendor.name,
      });
    }

    message.success(schedule && schedule.id ? 'Schedule saved' : 'Created new schedule');
    return fetchSchedule(catalogId, fromDate);
  };

  return (
    <Card title={schedule && schedule.id ? 'Schedule' : 'New Schedule'} bordered>
      <ScheduleForm
        form={form}
        tours={tours}
        catalogId={catalogId}
        fromDate={fromDate}
        schedule={schedule}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
      />
    </Card>
  );
};

export default ScheduleAdd;
