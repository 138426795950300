import { FormatDateTime } from '@/components/FormatDateTime';

export const columns = [
  {
    title: 'From Date',
    dataIndex: 'fromDate',
    hideInSearch: true,
  },
  {
    title: 'From Time',
    dataIndex: 'fromTime',
    hideInSearch: true,
  },
  {
    title: 'Quantity Free',
    dataIndex: 'quantityFree',
    hideInSearch: true,
  },
  {
    title: 'Quantity Max',
    dataIndex: 'quantityMax',
    hideInSearch: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    hideInSearch: true,
    ellipsis: {
      showTitle: false,
    },
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'From Date',
    key: 'fromDate',
    valueType: 'dateRange',
    hideInTable: true,
  },
];
