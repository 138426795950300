import React from 'react';
import { Typography } from 'antd';
import { FormatDateTime } from '@/components/FormatDateTime';
const { Text } = Typography;

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    render: (id, record) => (
      <Text style={{ width: 64 }} ellipsis={{ tooltip: id }} type="warning">
        {id}
      </Text>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'type',
    dataIndex: 'type',
  },
  {
    title: 'cron',
    dataIndex: 'cron',
  },
  {
    title: 'Status',
    dataIndex: 'active',
    render: active =>
      active ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>,
  },
  {
    title: 'Updated Date',
    dataIndex: 'updatedAt',
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    render: vendor => (vendor ? vendor.name : 'Unknow'),
  },
];

export default columns;
