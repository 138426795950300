import { Modal, Form, Input, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as AccountAPI from '@/services/accountAPI';
import { useSelector } from 'react-redux';
import { VendorType } from '@/constants/vendor.type';
import CardSelect from '@/components/CardSelect';

const AccountAddForm = ({ showAddForm, setShowAddForm, onFinish }) => {
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const doSubmit = useCallback(
    async values => {
      setSubmitting(true);
      const res = await AccountAPI.newAccount({ ...values, vendorType: activeVendor.name });
      setSubmitting(false);
      if (res && res.message) {
        setErrorMessage(res.message);
      } else {
        setErrorMessage(null);
        setShowAddForm(false);
        onFinish();
        message.success('Created new account');
      }
    },
    [activeVendor, setShowAddForm, onFinish]
  );

  useEffect(() => {
    if (!showAddForm) {
      form.resetFields();
    }
  }, [showAddForm, form]);

  return (
    <Modal
      title="New Account"
      open={showAddForm}
      width={640}
      onCancel={() => setShowAddForm(false)}
      onOk={() => form.submit()}
      confirmLoading={submitting}
      okText="Create"
    >
      <Form
        labelCol={{ span: 16, offset: 4 }}
        wrapperCol={{ span: 16, offset: 4 }}
        layout={'vertical'}
        form={form}
        onFinish={doSubmit}
        initialValues={{ vendor: 'SnAPP' }}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter account username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password placeholder="Enter account password" />
        </Form.Item>
        <Form.Item
          label="Credit Card"
          name="cardId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CardSelect />
        </Form.Item>
        {activeVendor && activeVendor.name === VendorType.Colosseum && (
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
        )}
        {activeVendor && activeVendor.name === VendorType.Colosseum && (
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
        )}
        <Form.Item>{errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}</Form.Item>
      </Form>
    </Modal>
  );
};

export default AccountAddForm;
