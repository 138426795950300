import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProFormDigit, ModalForm } from '@ant-design/pro-form';
import { PageContainer } from '@ant-design/pro-layout';
import { Link, useNavigate } from 'react-router-dom';
import { VendorType } from '@/constants/vendor.type';
import { Button, message, Typography } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import * as ToursAPI from '@/services/toursAPI';
import ProTable from '@ant-design/pro-table';
import { useSelector } from 'react-redux';
import { columns } from './tour.columns';

const { Text } = Typography;

const TourList = () => {
  const navigation = useNavigate();

  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const actionRef = useRef();
  const [showEditForm, setShowEditForm] = useState(false);
  const [tour, setTour] = useState(null);

  const fetchTours = useCallback(
    async (params, sort, filter) => {
      const content = await ToursAPI.getTourList({
        ...params,
        ...filter,
        ...sort,
        vendorId: activeVendorId,
      });
      if (content) {
        return { success: true, data: content, total: content.length };
      }
      return { success: false };
    },
    [activeVendorId]
  );

  const editSubmit = useCallback(
    async values => {
      const hide = message.loading('processing');
      const res = await ToursAPI.updateTour(tour.catalogId, values);
      hide();
      if (res && res.catalogId) {
        setShowEditForm(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      }
    },
    [actionRef, tour]
  );

  const action = {
    title: 'Action',
    key: 'action',
    valueType: 'option',
    render: (text, record) => [
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        key={`edit-${record.catalogId}`}
        onClick={() => {
          setTour({ ...record });
          setShowEditForm(true);
        }}
      >
        Edit
      </a>,
      activeVendor && activeVendor.name === VendorType.Colosseum ? (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <a
          key={`time-point-${record.catalogId}`}
          onClick={() => {
            navigation(`/tour-time-point/${record.catalogId}`);
          }}
        >
          Time Points
        </a>
      ) : null,
      <Link key={`availability-${record.catalogId}`} to={`/availability/${record.catalogId}`}>
        <Text type="warning">Availability</Text>
      </Link>,
    ],
  };

  useEffect(() => {
    if (activeVendorId && actionRef.current) {
      actionRef.current.reload();
    }
  }, [activeVendorId, actionRef]);

  return (
    <PageContainer>
      <ProTable
        headerTitle="Tour List"
        actionRef={actionRef}
        request={fetchTours}
        rowKey={record => record.catalogId}
        pagination={false}
        style={{ minWidth: 650, minHeight: 600 }}
        search={false}
        options={false}
        toolBarRender={() => [
          <Button
            type="link"
            icon={<SyncOutlined />}
            onClick={() => {
              if (actionRef.current) {
                actionRef.current.reload();
              }
            }}
          />,
        ]}
        columns={columns.concat(action)}
      />
      {showEditForm && (
        <ModalForm
          title="Edit Tour"
          width="400px"
          visible={showEditForm}
          onVisibleChange={visible => {
            setShowEditForm(visible);
          }}
          onFinish={editSubmit}
          initialValues={tour}
        >
          <ProFormDigit
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="maxTicketsPerAccount"
            label="Max Tickets Per Account"
          ></ProFormDigit>
        </ModalForm>
      )}
    </PageContainer>
  );
};

export default TourList;
