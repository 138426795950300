import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  BellOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  RedoOutlined,
  SettingOutlined,
  UserOutlined,
  GroupOutlined,
  BookOutlined,
} from '@ant-design/icons';

import Vendor from './Vendor';
import RightContent from './RightContent';
import styles from './index.module.less';
import { AuthContext } from '../pages/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAppAccounts,
  setAppTours,
  setAppGuides,
  setAppCards,
  setVendors,
  setActiveVendorId,
} from '@/store/AppReducer';
import * as AccountAPI from '@/services/accountAPI';
import * as ToursAPI from '@/services/toursAPI';
import * as GuideAPI from '@/services/guideAPI';
import * as VendorAPI from '@/services/vendorAPI';
import * as CardAPI from '@/services/cardAPI';
import { VendorType } from '@/constants/vendor.type';

const { Header, Sider, Content } = Layout;

const DefaultLayout = () => {
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('schedules');

  const location = useLocation();
  const navigation = useNavigate();
  const authContext = useContext(AuthContext);

  const dispatch = useDispatch();

  const fetchVendors = async () => {
    const response = await VendorAPI.getVendors();
    if (response) {
      dispatch(setVendors(response || []));
      const defaultVendor = response[0];
      if (!activeVendorId) {
        dispatch(setActiveVendorId(defaultVendor.id));
      }
    }
  };

  const fetchAccounts = async vendorId => {
    const response = await AccountAPI.accounts({ includeBalance: true, vendorId });
    dispatch(setAppAccounts(response ? response.content : []));
  };

  const fetchGuides = async vendorId => {
    const response = await GuideAPI.getGuideList({ pageSize: 1000, page: 1, vendorId });
    if (response && response.success) {
      dispatch(setAppGuides(response.data || []));
    }
  };

  const fetchCards = async () => {
    const response = await CardAPI.getCardList({ pageSize: 1000, page: 1 });
    if (response && response.success) {
      dispatch(setAppCards(response.data || []));
    }
  };

  const fetchTours = async vendorId => {
    const response = await ToursAPI.tree({ vendorId });
    if (response) {
      const newTours = response.map(tour => {
        tour.isLeaf = false;
        return tour;
      });
      dispatch(setAppTours(newTours));
    }
  };

  useEffect(() => {
    fetchVendors();
    fetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeVendorId) {
      fetchAccounts(activeVendorId);
      fetchTours(activeVendorId);
      fetchGuides(activeVendorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrent('schedules');
    } else if (location.pathname === '/tourist-group') {
      if (!activeVendor || activeVendor.name === VendorType.Colosseum) {
        setCurrent('tourist-group');
      } else {
        navigation('/');
      }
    } else if (location.pathname === '/job') {
      setCurrent('job');
    } else if (location.pathname === '/user') {
      if (!activeVendor || activeVendor.name === VendorType.SnApp) {
        setCurrent('user');
      } else {
        navigation('/');
      }
    } else if (location.pathname === '/ticket') {
      setCurrent('ticket');
    } else if (location.pathname === '/tour-list') {
      setCurrent('tour-list');
    } else if (location.pathname === '/guide') {
      setCurrent('guide');
    } else if (location.pathname === '/cron-job') {
      setCurrent('cron-job');
    } else if (location.pathname === '/card') {
      setCurrent('card');
    } else if (location.pathname === '/setting') {
      setCurrent('setting');
    } else if (location.pathname === '/global-log') {
      setCurrent('log');
    } else if (location.pathname === '/notification') {
      setCurrent('notification');
    } else if (location.pathname.startsWith('/availability/')) {
      setCurrent('tour-list');
    } else if (location.pathname === '/report') {
      if (!activeVendor || activeVendor.name === VendorType.Colosseum) {
        setCurrent('report');
      } else {
        navigation('/');
      }
    } else {
      setCurrent('schedules');
    }
    if (location.pathname === '/user' && !authContext.isRole('admin')) {
      navigation('/404');
    }
  }, [location, authContext, navigation, activeVendor]);

  return (
    <Layout className={styles.layout}>
      <Sider trigger={null} collapsible collapsed={collapsed} theme={'light'} width={180}>
        <p className={styles.logo}>TICKET AUTOMATION</p>
        <Menu theme="light" mode="inline" selectedKeys={[current]}>
          <Menu.Item key="schedules" icon={<DashboardOutlined />}>
            <Link to="/">Schedules</Link>
          </Menu.Item>
          {activeVendor && activeVendor.name === VendorType.Colosseum && (
            <Menu.Item key="tourist-group" icon={<GroupOutlined />}>
              <Link to="/tourist-group">Tourist Group</Link>
            </Menu.Item>
          )}
          <Menu.Item key="job" icon={<DashboardOutlined />}>
            <Link to="/job">Jobs</Link>
          </Menu.Item>
          {authContext.isRole('admin') && activeVendor && activeVendor.name === VendorType.SnApp && (
            <Menu.Item key="user" icon={<UserOutlined />}>
              <Link to="/user">User</Link>
            </Menu.Item>
          )}
          <Menu.Item key="ticket" icon={<ProfileOutlined />}>
            <Link to="/ticket">Ticket</Link>
          </Menu.Item>
          <Menu.Item key="tour-list" icon={<UserOutlined />}>
            <Link to="/tour-list">Tour</Link>
          </Menu.Item>
          <Menu.Item key="guide" icon={<UserOutlined />}>
            <Link to="/guide">Guide</Link>
          </Menu.Item>
          <Menu.Item key="notification" icon={<BellOutlined />}>
            <Link to="/notification">Notification</Link>
          </Menu.Item>
          <Menu.Item key="cron-job" icon={<RedoOutlined />}>
            <Link to="/cron-job">Cron Job</Link>
          </Menu.Item>
          <Menu.Item key="card" icon={<CreditCardOutlined />}>
            <Link to="/card">Credit Card</Link>
          </Menu.Item>
          <Menu.Item key="setting" icon={<SettingOutlined />}>
            <Link to="/setting">Setting</Link>
          </Menu.Item>
          <Menu.Item key="log" icon={<FileTextOutlined />}>
            <Link to="/global-log">Log</Link>
          </Menu.Item>
          {activeVendor && activeVendor.name === VendorType.Colosseum && (
            <Menu.Item key="report" icon={<BookOutlined />}>
              <Link to="/report">Report</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout className={styles['site-layout']}>
        <Header className={styles['site-layout-background']} style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: styles.trigger,
            onClick: () => setCollapsed(!collapsed),
          })}
          <Vendor />
          <div className={styles['header-right-container']}>
            <RightContent />
          </div>
        </Header>
        <Content
          className={styles['content-layout-background']}
          style={{
            margin: '24px 16px',
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
