import React, { useState } from 'react';
import { Button, Card, Col, List, Popconfirm, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setAppAccounts } from '@/store/AppReducer';
import AccountAddForm from './AccountAddForm';
import * as AccountAPI from '@/services/accountAPI';
import AccountEditForm from './AccountEditForm';
const { Text } = Typography;

const AccountCard = ({ onNewSchedule }) => {
  const dispatch = useDispatch();
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const accounts = useSelector(state => state.app.accounts);

  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const fetchAccounts = async () => {
    setLoading(true);
    const response = await AccountAPI.accounts({ includeBalance: true, vendorId: activeVendorId });
    dispatch(setAppAccounts(response ? response.content : []));
    setLoading(false);
  };

  const handleActiveAccount = async (account, active) => {
    setLoading(true);
    const response = await AccountAPI.update(account.id, { active });
    if (response) {
      fetchAccounts();
    }
    setLoading(false);
  };

  const handleEditAccount = async account => {
    setCurrentRow(account);
    setShowEditForm(true);
  };

  const handleDeleteAccount = async account => {
    setLoading(true);
    const response = await AccountAPI.remove(account.id);
    if (response) {
      fetchAccounts();
    }
    setLoading(false);
  };

  return (
    <Card title="Accounts" bordered={false} loading={loading} style={{ minWidth: 950 }}>
      <List
        itemLayout="horizontal"
        dataSource={accounts}
        rowKey={record => record.id}
        renderItem={item => (
          <List.Item
            extra={
              <Space>
                {item.active ? (
                  <Button onClick={() => handleActiveAccount(item, false)}>Deactivate</Button>
                ) : (
                  <Button onClick={() => handleActiveAccount(item, true)}>Activate</Button>
                )}
                <Button onClick={() => handleEditAccount(item)}>Edit</Button>
                <Popconfirm
                  title="Are you sure to delete this account?"
                  onConfirm={() => handleDeleteAccount(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>Delete</Button>
                </Popconfirm>
              </Space>
            }
          >
            <List.Item.Meta title={<Text>{item.username}</Text>} description={item.balanceRaw} />
            <List.Item.Meta title={<Text>{`${item.card?.name || ''} ${item.card?.cardNumberLast4 || ''}`}</Text>} />
            <List.Item.Meta title={`${item.firstName || ''} ${item.lastName || ''}`} />
            {item.active ? (
              <List.Item.Meta style={{ flexGrow: 0.3 }} title={<span style={{ color: 'green' }}>Active</span>} />
            ) : (
              <List.Item.Meta style={{ flexGrow: 0.3 }} title={<span style={{ color: 'red' }}>Inactive</span>} />
            )}
          </List.Item>
        )}
        footer={
          <Row>
            <Col offset={8} span={8}>
              <Button size={'large'} block icon={<PlusOutlined />} onClick={() => setShowAddForm(true)}>
                New Account
              </Button>
            </Col>
          </Row>
        }
      />
      <AccountAddForm showAddForm={showAddForm} setShowAddForm={setShowAddForm} onFinish={fetchAccounts} />
      <AccountEditForm
        showEditForm={showEditForm}
        setShowEditForm={setShowEditForm}
        onFinish={fetchAccounts}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      ></AccountEditForm>
    </Card>
  );
};

export default AccountCard;
