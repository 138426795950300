import { FormatDateTime } from '@/components/FormatDateTime';

const columns = [
  {
    dataIndex: 'index',
    valueType: 'index',
    width: 48,
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    hideInSearch: true,
    sorter: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    hideInSearch: true,
    sorter: true,
  },
  {
    title: 'Licence Number',
    dataIndex: 'licenceNumber',
    hideInSearch: true,
    sorter: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    hideInSearch: true,
    sorter: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    hideInSearch: true,
    sorter: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Search',
    hideInTable: true,
    dataIndex: 'keywords',
  },
];

export default columns;
