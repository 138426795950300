import { FormatDateTime } from '@/components/FormatDateTime';

const columns = [
  {
    dataIndex: 'index',
    valueType: 'index',
    width: 48,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    hideInSearch: true,
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    hideInSearch: true,
  },
  {
    title: 'Holder',
    dataIndex: 'holderFirstName',
    hideInSearch: true,
    render: (_, record) => `${record.holderFirstName} ${record.holderLastName}`,
  },
  {
    title: 'Last4',
    dataIndex: 'cardNumberLast4',
    hideInSearch: true,
  },
  {
    title: 'Expire Date',
    dataIndex: 'expireDate',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Is Divvy Card',
    dataIndex: 'isDivvyCard',
    valueEnum: {
      0: {
        text: 'False',
      },
      1: {
        text: 'True',
      },
    },
    hideInSearch: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Search',
    hideInTable: true,
    dataIndex: 'keywords',
  },
];

export default columns;
