import React, { useRef, useEffect } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { notifications } from '@/services/notificationAPI';
import { columns } from './notification.columns';
import { useSelector } from 'react-redux';

const TicketList = () => {
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const actionRef = useRef();

  const fetchNotifications = async params => {
    const { pageSize, current, ...otherParams } = params;
    return notifications({
      size: pageSize,
      page: current,
      ...otherParams,
      vendorId: activeVendorId,
    });
  };

  useEffect(() => {
    if (activeVendorId && actionRef.current) {
      actionRef.current.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  return (
    activeVendorId && (
      <PageContainer>
        <ProTable
          actionRef={actionRef}
          style={{ minWidth: 1000 }}
          columns={columns}
          request={fetchNotifications}
          rowKey="id"
          search={false}
          pagination={{
            pageSize: 10,
          }}
          dateFormatter="string"
          headerTitle="Notification"
          options={false}
        />
      </PageContainer>
    )
  );
};

export default TicketList;
