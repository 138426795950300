import React, { useState } from 'react';
import moment from 'moment';
import { Col, Form, Row, Card, Button, InputNumber, Typography } from 'antd';
import { VendorType } from '@/constants/vendor.type';
import ToursSelect from '@/components/TourSelect';
import ScheduleJobForm from '../ScheduleJobForm';
import styles from './index.module.less';
import CustomCalendar from './CustomCalendar';
import { useSelector } from 'react-redux';

const ScheduleForm = ({ fromDate, form, tours, schedule, onValuesChange, onFinish }) => {
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const [loading, setLoading] = useState(false);
  const [tour, setTour] = useState(null);
  const [purchasedNumber, setPurchasedNumber] = useState(0);

  const handleFormFinish = async values => {
    setLoading(true);
    const result = await onFinish(values);
    setLoading(false);
    return result;
  };

  return (
    <Form layout={'vertical'} form={form} onFinish={handleFormFinish} onValuesChange={onValuesChange}>
      <Row gutter={48} className={styles.layout}>
        <Col xs={8}>
          <Form.Item
            label="Available Tours"
            name="tour"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <ToursSelect tours={tours} setTour={setTour} />
          </Form.Item>
          <Form.Item
            label="Available Dates"
            name="date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <CustomCalendar />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.tour !== currentValues.tour || prevValues.date !== currentValues.date
            }
          >
            {form.getFieldValue('tour') && form.getFieldValue('date') ? (
              <div>
                <Row justify="space-between">
                  <Form.Item
                    label="Max Tickets Per Day"
                    name="maxTicketsPerDay"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber min={1} max={1000} />
                  </Form.Item>
                  <Typography>Purchased Number: {purchasedNumber}</Typography>
                </Row>
                {activeVendor && activeVendor.name === VendorType.SnApp && (
                  <Form.Item label="Retry Max Times" name="retry">
                    <InputNumber />
                  </Form.Item>
                )}
                <Form.Item>
                  <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={moment().format('YYYY-MM-DD') > fromDate}
                      >
                        {schedule && schedule.id ? 'Save' : ''}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            ) : null}
          </Form.Item>
        </Col>
        <Col xs={16}>
          <Card>
            {form.getFieldValue('tour') && form.getFieldValue('date') && (
              <ScheduleJobForm
                tour={tour}
                fromDate={fromDate}
                schedule={schedule}
                form={form}
                handleCreateSchedule={handleFormFinish}
                setPurchasedNumber={setPurchasedNumber}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default ScheduleForm;
