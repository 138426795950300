import qs from 'qs';
import request from '@/utils/request';

export async function tree(payload) {
  return request(`/tours/tree${qs.stringify(payload, { addQueryPrefix: true })}`);
}

export async function getTourAvailability(payload) {
  return request(`/tours/availability${qs.stringify(payload, { addQueryPrefix: true })}`);
}

export async function getTourList(payload) {
  return request(`/tours/list${qs.stringify(payload, { addQueryPrefix: true })}`);
}

export async function updateTour(id, body) {
  return request(`/tours/${id}`, {
    method: 'PATCH',
    body,
  });
}

export async function refreshTour(id) {
  return request(`/tours/refresh/${id}`, {
    method: 'PATCH',
  });
}

export async function getTour(id) {
  return request(`/tours/${id}`);
}
