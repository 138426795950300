import React, { useCallback, useEffect, useRef } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { getTourPurchasedList } from '@/services';
import { columns } from './tour.purchased.column';
import ProTable from '@ant-design/pro-table';
import { useSelector } from 'react-redux';

const TourPurchasedList = () => {
  const ref = useRef();

  const activeVendorId = useSelector(state => state.app.activeVendorId);

  const handleSearch = useCallback(
    async params => {
      const res = await getTourPurchasedList({ ...params, vendorId: activeVendorId });
      if (res.content) {
        return { success: true, data: res.content, total: res.content.length };
      }
      return { success: false };
    },
    [activeVendorId]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.reload();
    }
  }, [activeVendorId]);

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns}
        request={handleSearch}
        columnsState={{
          persistenceKey: 'pro-table-singe-tour-purchased-list',
          persistenceType: 'localStorage',
        }}
        rowKey="date"
        search={{}}
        pagination={false}
        dateFormatter="string"
        headerTitle="Tour Purchased List"
        options={false}
        actionRef={ref}
      />
    </PageContainer>
  );
};

export default TourPurchasedList;
