import request from '@/utils/request';
import qs from 'qs';

export async function getGuideList(params, sort) {
  const res = await request(
    `/guides/page${qs.stringify(
      {
        size: params.pageSize,
        page: params.current,
        ...params,
        sort,
      },
      { addQueryPrefix: true }
    )}`
  );
  if (res?.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function saveGuide(body) {
  return request(
    '/guides',
    {
      method: 'POST',
      body,
    },
    false
  );
}

export async function updateGuide(id, body) {
  return await request(
    `/guides/${id}`,
    {
      method: 'PATCH',
      body,
    },
    false
  );
}

export async function deleteGuide(id) {
  const res = await request(`/guides/${id}`, {
    method: 'DELETE',
  });
  if (res && res.id) {
    return res;
  }
  return false;
}

export async function importGuide(body) {
  return request(
    '/guides/import',
    {
      method: 'POST',
      body,
    },
    false
  );
}
