import { FormatDateTime } from '@/components/FormatDateTime';

const columns = [
  {
    dataIndex: 'index',
    valueType: 'index',
    width: 48,
  },
  {
    title: 'Email',
    dataIndex: ['email', 'email'],
    copyable: true,
    ellipsis: false,
    hideInSearch: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    hideInSearch: true,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    valueEnum: {
      admin: {
        text: 'Admin',
      },
      coordinator: {
        text: 'Coordinator',
      },
    },
    hideInSearch: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Search',
    hideInTable: true,
    dataIndex: 'keywords',
  },
];

export default columns;
