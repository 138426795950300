import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { deleteGroup, getGroupList } from '@/services/groupAPI';
import { Button, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import columns from './group.columns';
import GroupAddForm from './GroupAddForm';
import GroupEditForm from './GroupEditForm';
import { useSelector } from 'react-redux';

const GroupList = () => {
  const tours = useSelector(state => state.app.tours);
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const filteredTours = useMemo(
    () =>
      tours?.filter(
        tour =>
          tour.catalogId === 'DDDA3AB3-47BC-0A49-7752-0174490F632A' ||
          tour.catalogId === '06B97E06-CA46-981E-360A-017A9FE4C3F6'
      ),
    [tours]
  );

  const actionRef = useRef();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const fetchGroups = useCallback(
    async (params, sort, filter) => {
      return getGroupList({ ...params, vendorId: activeVendorId }, sort, filter);
    },
    [activeVendorId]
  );

  const onFormFinish = useCallback(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, []);

  const deleteSubmit = useCallback(async values => {
    const hide = message.loading('processing');
    const success = await deleteGroup(values.id);
    hide();
    if (success) {
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  }, []);

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [activeVendorId]);

  const action = {
    title: 'Action',
    valueType: 'option',
    render: (text, record) => [
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        onClick={() => {
          setCurrentRow({ ...record });
          setShowEditForm(true);
        }}
      >
        Edit
      </a>,
      record.isUsed || record.jobCount > 0 ? null : (
        <Popconfirm
          title="Are you sure to delete this group?"
          onConfirm={() => deleteSubmit(record)}
          okText="Yes"
          cancelText="No"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={() => {
              setCurrentRow(record);
            }}
          >
            Delete
          </a>
        </Popconfirm>
      ),
    ],
  };

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        actionRef={actionRef}
        request={fetchGroups}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={false}
        form={{}}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        headerTitle="Group List"
        options={false}
        toolBarRender={() => [
          <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => setShowAddForm(true)}>
            Add
          </Button>,
        ]}
      />
      <GroupAddForm
        tours={filteredTours}
        showAddForm={showAddForm}
        setShowAddForm={setShowAddForm}
        onFinish={onFormFinish}
      ></GroupAddForm>
      <GroupEditForm
        tours={filteredTours}
        showEditForm={showEditForm}
        setShowEditForm={setShowEditForm}
        onFinish={onFormFinish}
        currentRow={currentRow}
      ></GroupEditForm>
    </PageContainer>
  );
};

export default GroupList;
