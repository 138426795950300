import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
import styles from './Form.module.css';

const FormItem = Form.Item;
const ResetPasswordForm = ({ onFinish }) => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleFormFinish = values => {
    if (onFinish) {
      onFinish(values);
    }
  };

  return (
    <div className={styles.form}>
      <h2>Reset Password</h2>
      <p className={styles['signup-message']}>Please enter your email code and password , we will reset password</p>
      <Form onFinish={handleFormFinish} layout={'vertical'} initialValues={query}>
        <FormItem
          label={'Your Email'}
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="Enter your email" />
        </FormItem>
        <FormItem
          hidden={true}
          label={'Your Code'}
          name="code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="Enter your Code" />
        </FormItem>
        <FormItem
          label={'Your Password'}
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password size="large" placeholder="Enter your password" />
        </FormItem>
        <FormItem
          label={'Password Confirmation'}
          name="passwordConfirmation"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password size="large" placeholder="Confirmatio your password" />
        </FormItem>
        <Form.Item>
          <Link className={styles.resetPassword} to="/login">
            Signin
          </Link>
        </Form.Item>
        <FormItem style={{ marginTop: '0.4rem' }}>
          <Button htmlType="submit" type="primary" size="large" block className={styles['btn-login']}>
            Reset password
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
