import React from 'react';

const keyOfToken = 'Ticket-Scraper-Token';
const keyOfUser = 'Ticket-Scraper-User';

const AuthContext = React.createContext({
  login: (token, callback) => {},
  hasAuth: () => {},
  getUser: () => {},
  setUser: data => {},
  logout: callback => {},
  token: () => {},
  isRole: role => {},
});

const AuthContextValue = {
  getUser: () => {
    return JSON.parse(localStorage.getItem(keyOfUser) || '');
  },
  setUser: data => {
    const user = JSON.parse(localStorage.getItem(keyOfUser) || '');
    localStorage.setItem(keyOfUser, JSON.stringify({ ...user, ...data }));
  },
  login: (token, user, callback) => {
    localStorage.setItem(keyOfToken, token);
    localStorage.setItem(keyOfUser, JSON.stringify(user));
    callback();
  },
  hasAuth: () => {
    return localStorage.getItem(keyOfToken) != null;
  },
  logout: callback => {
    localStorage.removeItem(keyOfToken);
    callback();
  },
  token: () => {
    return localStorage.getItem(keyOfToken);
  },
  isRole: role => {
    const user = JSON.parse(localStorage.getItem(keyOfUser) || '');
    return user && user.role === role;
  },
};

export { AuthContext, AuthContextValue };
