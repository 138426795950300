import { ModalForm, ProFormDatePicker, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { saveGroup } from '@/services/groupAPI';
import { useState } from 'react';
import { Form, message } from 'antd';
import ToursSelect from '@/components/TourSelect';
import * as moment from 'moment';
import styles from './index.module.less';

const GroupAddForm = ({ tours, showAddForm, setShowAddForm, onFinish }) => {
  const [tour, setTour] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const addSubmit = async values => {
    const hide = message.loading('processing');
    const res = await saveGroup({
      name: values.name,
      catalogId: tour.catalogId,
      date: values.date,
      touristList: values.touristList ? values.touristList.split('\n').filter(a => a.trim()) : [],
    });
    hide();
    if (res && res.id) {
      setErrorMessage(null);
      setShowAddForm(false);
      onFinish();
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    showAddForm && (
      <ModalForm
        title="Add Group"
        width="400px"
        visible={showAddForm}
        onVisibleChange={visible => {
          setShowAddForm(visible);
          if (!visible) {
            setErrorMessage(null);
          }
        }}
        initialValues={{}}
        onFinish={addSubmit}
        className={styles.groupAddForm}
      >
        <ProFormText
          rules={[
            {
              required: true,
            },
          ]}
          width="md"
          name="name"
          label="Name"
        ></ProFormText>
        <Form.Item
          label="Available Tours"
          name="tour"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ToursSelect tours={tours} setTour={setTour} />
        </Form.Item>
        <ProFormDatePicker
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  if (moment(value).isBefore(moment())) {
                    return Promise.reject(`Date has passed`);
                  }
                  return Promise.resolve();
                } else {
                  return Promise.reject('Please enter date');
                }
              },
            },
          ]}
          width="md"
          name="date"
          label="Date"
        ></ProFormDatePicker>
        <ProFormTextArea
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Please enter tourist list');
                }
              },
            },
          ]}
          width="md"
          name="touristList"
          label="Tourist List"
        ></ProFormTextArea>
        {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
      </ModalForm>
    )
  );
};

export default GroupAddForm;
