import React, { useCallback, useRef, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { deleteCard, getCardList } from '@/services/cardAPI';
import { Button, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import columns from './card.columns';
import CardAddForm from './CardAddForm';
import CardEditForm from './CardEditForm';

const CardList = () => {
  const actionRef = useRef();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const onFormFinish = useCallback(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, []);

  const deleteSubmit = useCallback(async values => {
    const hide = message.loading('processing');
    const success = await deleteCard(values.id);
    hide();
    if (success) {
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  }, []);

  const action = {
    title: 'Action',
    valueType: 'option',
    render: (text, record) => [
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        onClick={() => {
          setCurrentRow({ ...record });
          setShowEditForm(true);
        }}
      >
        Edit
      </a>,
      <Popconfirm
        title="Are you sure to delete this user?"
        onConfirm={() => deleteSubmit(record)}
        okText="Yes"
        cancelText="No"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            setCurrentRow(record);
          }}
        >
          Delete
        </a>
      </Popconfirm>,
    ],
  };

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        actionRef={actionRef}
        request={getCardList}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={false}
        form={{}}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        headerTitle="Card List"
        options={false}
        toolBarRender={() => [
          <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => setShowAddForm(true)}>
            Add
          </Button>,
        ]}
      />
      <CardAddForm showAddForm={showAddForm} setShowAddForm={setShowAddForm} onFinish={onFormFinish}></CardAddForm>
      <CardEditForm
        showEditForm={showEditForm}
        setShowEditForm={setShowEditForm}
        onFinish={onFormFinish}
        currentRow={currentRow}
      ></CardEditForm>
    </PageContainer>
  );
};

export default CardList;
