import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as LoginAPI from '@/services/loginAPI';
import { AuthContext } from '@/pages/AuthContext';

import ResetPasswordForm from './Form';
import Logo from '../login/Logo';

import styles from './index.module.css';

const ResetPassword = ({ renderLogo = () => <Logo /> }) => {
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        requestAnimationFrame(() => resize());
      },
      { passive: true }
    );
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    let fontSize = document.documentElement.clientWidth / 19.2;
    if (fontSize <= 12) {
      fontSize = 12;
    }
    document.documentElement.style.fontSize = fontSize + 'px';
  };

  const navigate = useNavigate();
  const contextValue = useContext(AuthContext);
  const submit = async (values, contextValue) => {
    const response = await LoginAPI.resetPassword(values);
    if (response && response.ok) {
      navigate('/login');
    }
  };

  return (
    <div className={styles.layout}>
      {renderLogo && renderLogo()}
      <ResetPasswordForm onFinish={values => submit(values, contextValue)} />
    </div>
  );
};

export default ResetPassword;
