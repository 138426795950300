import { Badge, Button, Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormatDateTime } from '@/components/FormatDateTime';
import * as LogAPI from '@/services/logAPI';
import DetailModal from '@/components/log/DetailModal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const LogTable = () => {
  const activeVendorId = useSelector(state => state.app.activeVendorId);

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = React.useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [logDetail, setLogDetail] = React.useState({});

  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
    defaultCurrent: 1,
    defaultPageSize: 20,
    showSizeChanger: true,
  });

  useEffect(() => {
    fetchLogs({ page: pagination.current, size: pagination.pageSize, vendorId: activeVendorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize, activeVendorId]);

  const fetchLogs = async payload => {
    setLoading(true);
    const response = await LogAPI.page(payload);
    if (response) {
      const { total, content } = response;
      setLogs(content || []);
      setPagination({ ...pagination, total });
      setLoading(false);
    }
  };

  const fetchDetail = async (id, createdAt) => {
    setLoading(true);
    const response = await LogAPI.getById(id, createdAt);
    if (response) {
      setLogDetail(response);
    }
    setLoading(false);
  };

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  const handleDetailVisible = record => {
    setDetailVisible(true);
    fetchDetail(record.id, record.createdAt);
  };

  return (
    <>
      <Table
        style={{ minWidth: 1000 }}
        loading={loading}
        dataSource={logs}
        rowKey={record => record.id}
        pagination={pagination}
        onChange={handleTableChange}
        columns={[
          {
            title: 'Request URL',
            dataIndex: 'reqUrl',
            key: 'reqUrl',
          },
          {
            title: 'Request Method',
            dataIndex: 'reqMethod',
            key: 'reqMethod',
            ellipsis: {
              showTitle: false,
            },
          },
          {
            title: 'Schedule ID',
            dataIndex: ['fragment', 'schedule'],
            key: 'traceId',
            render: (value, record) => (
              <Link to={`/schedule?catalogId=${value.tourId}&fromDate=${value.fromDate}`}>
                <Text style={{ width: 64 }} ellipsis={{ tooltip: value.id }} type="warning">
                  {value.id}
                </Text>
              </Link>
            ),
          },
          {
            title: 'Status',
            dataIndex: 'respStatus',
            key: 'status',
            ellipsis: {
              showTitle: false,
            },
            render: (value, record) => {
              if (value < 400) {
                return <Badge status="success" text={`${value} ok`} />;
              }
              return <Badge status="error" text={`${value} ${record.errorMessage}`} />;
            },
          },
          {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            ellipsis: {
              showTitle: false,
            },
            render: note => (
              <Tooltip placement="topLeft" title={note}>
                {note}
              </Tooltip>
            ),
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: {
              showTitle: false,
            },
            render: t => <FormatDateTime time={t}></FormatDateTime>,
          },
          {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (text, record) => (
              <Button type={'link'} onClick={() => handleDetailVisible(record)}>
                Detail
              </Button>
            ),
          },
        ]}
      />
      <DetailModal visible={detailVisible} handleModalCancel={() => setDetailVisible(false)} log={logDetail} />
    </>
  );
};

export default LogTable;
