import React, { useEffect } from 'react';
import { Cascader } from 'antd';

const ToursSelect = ({ onChange, value, tours, setTour }) => {
  useEffect(() => {
    if (tours && tours.length > 0 && value && value.length > 0) {
      value.reduce((prev, item, index) => {
        const currentItem = prev?.find(a => a.catalogId === item);
        if (index === value.length - 1) {
          setTour(currentItem);
        }
        return currentItem?.children;
      }, tours);
    }
  }, [tours, value, setTour]);

  return (
    <Cascader
      fieldNames={{
        label: 'catalogName',
        value: 'catalogId',
        children: 'children',
      }}
      placeholder={'Select tours'}
      value={tours && tours.length > 0 && JSON.stringify(tours).includes(value) ? value : null}
      options={tours.map(t => ({ ...t, isLeaf: !t.children || t.children.length === 0 }))}
      onChange={(value, options) => {
        onChange(value, options);
      }}
      allowClear={true}
      onClear={() => setTour(null)}
    ></Cascader>
  );
};

export default ToursSelect;
