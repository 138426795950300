import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import ProTable, { EditableProTable } from '@ant-design/pro-table';
import { getColumns, getGroupColumns } from './index.column';
import * as FragmentAPI from '@/services/fragmentAPI';
import { getGroupList } from '@/services/groupAPI';
import { Button, Card, message, Spin } from 'antd';
import { AuthContext } from '@/pages/AuthContext';
import JobDetailsModal from './JobDetailsModal';
import { usePrompt } from '@/utils/usePrompt';
import { useSelector } from 'react-redux';
import JobFormModal from './JobFormModal';
import moment, { isMoment } from 'moment';
import { VendorType } from '@/constants';
import styles from './index.module.less';
import { io } from 'socket.io-client';

const ScheduleJobForm = ({ fromDate, tour, schedule, form, handleCreateSchedule, setPurchasedNumber }) => {
  const authContext = useContext(AuthContext);

  const guides = useSelector(state => state.app.guides);
  const accounts = useSelector(state => state.app.accounts);
  const tours = useSelector(state => state.app.tours);
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const useTouristList = useMemo(
    () =>
      tour &&
      (tour.catalogId === 'DDDA3AB3-47BC-0A49-7752-0174490F632A' ||
        tour.catalogId === '06B97E06-CA46-981E-360A-017A9FE4C3F6'),
    [tour]
  );

  const [loading, setLoading] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [compete, setCompete] = useState(false);
  const [dryRun, setDryRun] = useState(false);
  const [clientTicketsNumber, setClientTicketsNumber] = useState(null);
  const [groups, setGroups] = useState([]);
  const [touristGroups, setTouristGroups] = useState([]);

  const groupTableRef = useRef();

  const [dataSource, setDataSource] = useState([]);
  const [editableKeys, setEditableRowKeys] = useState([]);

  usePrompt('Are you sure want to leave, there are still unsaved data', dataSource && dataSource.length > 0);

  const fetchGroups = useCallback(async (catalogId, date, vendorId) => {
    const res = await getGroupList({
      catalogId,
      date,
      vendorId,
      isUsed: 0,
      page: 1,
      size: 20,
    });
    if (res) {
      setTouristGroups(res.data);
    }
  }, []);

  useEffect(() => {
    if (fromDate && tour && activeVendorId) {
      fetchGroups(tour.catalogId, fromDate, activeVendorId);
    }
  }, [fromDate, tour, activeVendorId, fetchGroups]);

  const saveScheduleJob = useCallback(
    async values => {
      let scheduleId = schedule?.id;
      if (!scheduleId) {
        const result = await handleCreateSchedule(form.getFieldsValue());
        if (result.id) {
          scheduleId = result.id;
        } else {
          message.error('create schedule fail');
        }
      }
      values.guide = guides.find(g => g.id === values.guide);
      const postData = {
        scheduleId,
        clientTicketsNumber: values.clientTicketsNumber,
        fromTime: values.fromTime,
        toTime: values.toTime,
        accountId: accounts.find(a => a.id === values.account)?.id,
        compete: values.compete,
        dryRun: values.dryRun,
        jobsCount: values.jobsCount,
        touristGroups: values.touristGroups ? values.touristGroups : [],
      };
      const response = await FragmentAPI.create(postData);
      if (response.error || response.message) {
        message.error(response.message);
        throw new Error(response.message); // make not remove editableKeys
      } else {
        message.success('Created new schedule job');
        // remove temp data
        setDataSource(prev => {
          const list = dataSource;
          const index = list.findIndex(f => f.tempId === values.tempId);
          list[index].id = response[0].id;
          return [...list];
        });
        if (groupTableRef.current) {
          groupTableRef.current.reload();
        }
      }
    },
    [groupTableRef, dataSource, setDataSource, guides, schedule, accounts, form, handleCreateSchedule]
  );

  const saveScheduleJobBatch = async () => {
    let scheduleId = schedule?.id;
    if (!scheduleId) {
      const result = await handleCreateSchedule(form.getFieldsValue());
      if (result.id) {
        scheduleId = result.id;
      } else {
        message.error('create schedule fail');
      }
    }

    const tempData = dataSource.map(f => {
      f.guide = guides.find(g => g.id === f.guide);
      return f;
    });
    if (!tempData || tempData.length === 0) {
      message.info('No unsaved data');
      return;
    }
    const postData = tempData.map(f => {
      const data = {
        scheduleId,
        clientTicketsNumber: f.clientTicketsNumber,
        fromTime: f.fromTime,
        toTime: f.toTime,
        accountId: accounts.find(a => a.id === f.account)?.id,
        compete: f.compete,
        dryRun: f.dryRun,
        jobsCount: f.jobsCount,
        touristGroups: f.touristGroups ? f.touristGroups : [],
      };
      return data;
    });
    setLoading(true);
    const response = await FragmentAPI.createBatch(postData);
    if (response.error || response.message) {
      message.error(response.message);
    } else {
      message.success('Created new schedule job');
      // remove temp data
      setDataSource([]);
      if (groupTableRef.current) {
        groupTableRef.current.reload();
      }
    }
    setLoading(false);
  };

  const handleTempSave = async values => {
    const selectedGroups = touristGroups.filter(g => !!values.touristGroups?.find(id => id === g.id));
    let editableData = [];
    if (values.time) {
      editableData = values.time.map(t => {
        return {
          fromTime: t,
          account: values.accountId,
          maxTicketsPerAccount: 1,
          clientTicketsNumber:
            values.touristGroups && values.touristGroups.length > 0
              ? selectedGroups[0].touristCount
              : values.clientTicketsNumber,
          tempId: t + '_' + Date.now().toString(),
          compete: values.compete,
          dryRun: values.dryRun,
          jobsCount: values.jobsCount,
          touristGroups: values.touristGroups && values.touristGroups.length > 0 ? values.touristGroups : [],
        };
      });
    }

    if (values.timeRange) {
      const fromTime = values.timeRange[0].format('HH:mm');
      const toTime = values.timeRange[1].format('HH:mm');
      editableData = [
        {
          fromTime: fromTime,
          toTime: toTime,
          account: values.accountId,
          maxTicketsPerAccount: 1,
          clientTicketsNumber: values.clientTicketsNumber,
          tempId: fromTime + '_' + toTime + '_' + Date.now().toString(),
          compete: values.compete,
          dryRun: values.dryRun,
          jobsCount: values.jobsCount,
          touristGroups: values.touristGroups ? values.touristGroups : [],
        },
      ];
    }

    setDataSource(prev => prev.concat(editableData));
    setEditableRowKeys(prev => prev.concat(editableData.map(d => d.tempId)));
    setFormModalVisible(false);
  };

  const fetchFragmentsGroup = useCallback(async () => {
    if (!schedule || !schedule.id) {
      setPurchasedNumber(0);
      return { success: true, content: [], total: 0 };
    }
    setLoading(true);
    const result = await FragmentAPI.group({ scheduleId: schedule.id });
    setLoading(false);
    setGroups(result.data);
    setPurchasedNumber(
      result.data.reduce((prev, item) => {
        return prev + Number.parseInt(item.purchasedNumber);
      }, 0)
    );
    return result;
  }, [schedule, setPurchasedNumber, setLoading, setGroups]);

  const openDetailModal = record => {
    setFromTime(record.fromTime);
    setToTime(record.toTime);
    setCompete(record.compete);
    setDryRun(record.dryRun);
    setClientTicketsNumber(record.clientTicketsNumber);
    setDetailModalVisible(true);
  };

  useEffect(() => {
    // remove temp data
    setDataSource([]);
    setEditableRowKeys([]);
    if (groupTableRef.current) {
      groupTableRef.current.reload();
    }
  }, [schedule]);

  useEffect(() => {
    let refreshTimePoint = null;

    if (tours.length > 0) {
      const client = io(`${process.env.REACT_APP_SOCKET_IO_BASE_URL}/fragment`, {
        transports: ['websocket'],
        auth: {
          token: authContext.token(),
        },
      });

      if (client && client.active) {
        client.emit('subscribe', {
          fragment: {},
        });
        client.on('data:schedules', data => {
          const nowDatetime = moment();
          if (
            !refreshTimePoint ||
            (isMoment(refreshTimePoint) && refreshTimePoint.clone().add(15, 'seconds').isBefore(nowDatetime))
          ) {
            if (groupTableRef.current) {
              groupTableRef.current.reload();
              refreshTimePoint = moment();
            }
          }
        });
      }

      return () => {
        if (client) {
          client.close();
          delete client.io.nsps['/fragment'];
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tours]);

  return (
    <Spin spinning={loading}>
      <Card title="Add jobs" bordered>
        <div
          style={{
            marginBottom: 12,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={() => setFormModalVisible(true)} disabled={moment().format('YYYY-MM-DD') > fromDate}>
            Add New Jobs
          </Button>
          <Button
            onClick={saveScheduleJobBatch}
            style={{ marginLeft: 10 }}
            disabled={moment().format('YYYY-MM-DD') > fromDate || !dataSource || dataSource.length === 0}
          >
            Save All Jobs
          </Button>
        </div>
        <EditableProTable
          loading={false}
          className={styles.editTable}
          rowKey={'tempId'}
          columns={getColumns(schedule, tour, accounts, activeVendor, authContext)}
          value={dataSource}
          pagination={false}
          recordCreatorProps={{
            disabled: moment().format('YYYY-MM-DD') > fromDate,
            creatorButtonText: 'Add a job',
            newRecordType: 'dataSource',
            record: (index, data) => {
              const fromTime = schedule?.tour?.timePoints[0]?.timePoint.substring(0, 5);
              return {
                fromTime: fromTime,
                tempId: fromTime + '_' + Date.now(),
                account: 'automation',
                maxTicketsPerAccount: 1,
                clientTicketsNumber: 1,
                jobsCount: activeVendor && activeVendor.name === VendorType.Colosseum ? 48 : 1,
                compete: false,
                dryRun: false,
              };
            },
          }}
          editable={{
            type: 'multiple',
            editableKeys,
            newLineConfig: {
              options: {
                position: 'bottom',
              },
            },
            actionRender: (row, config, defaultDom) => {
              return [defaultDom.save, defaultDom.delete];
            },
            onValuesChange: (record, dataSource) => {
              setDataSource([...dataSource.filter(f => !f.id)]);
            },
            onSave: async (rowKey, data, row) => {
              await saveScheduleJob(data);
            },
            onChange: setEditableRowKeys,
            deletePopconfirmMessage: 'Are you sure want to delete?',
            onlyOneLineEditorAlertMessage: 'Only edit a new row',
            onlyAddOneLineAlertMessage: 'Only add a new line',
            tableName: 'Schedule Job',
            saveText: 'Save',
            cancelText: 'Cancel',
            deleteText: 'Delete',
          }}
        />
      </Card>
      <Card title="Scheduled jobs" bordered style={{ marginTop: 20 }}>
        <ProTable
          className={styles.groupTable}
          loading={false}
          actionRef={groupTableRef}
          columns={getGroupColumns(schedule, openDetailModal)}
          request={fetchFragmentsGroup}
          columnsState={{
            persistenceKey: 'pro-table-singe-demos',
            persistenceType: 'localStorage',
          }}
          rowKey={record =>
            record.fromTime + record.toTime + record.clientTicketsNumber + record.compete + record.dryRun
          }
          search={false}
          pagination={false}
          options={false}
        ></ProTable>
      </Card>
      {detailModalVisible && (
        <JobDetailsModal
          tour={tour}
          accounts={accounts}
          guides={guides}
          visible={detailModalVisible}
          setVisible={setDetailModalVisible}
          schedule={schedule}
          groups={groups}
          fromTime={fromTime}
          toTime={toTime}
          compete={compete}
          dryRun={dryRun}
          clientTicketsNumber={clientTicketsNumber}
        ></JobDetailsModal>
      )}
      <JobFormModal
        fromDate={fromDate}
        tour={tour}
        visible={formModalVisible}
        setVisible={setFormModalVisible}
        schedule={schedule}
        groups={touristGroups}
        onFinish={handleTempSave}
        useTouristList={useTouristList}
      ></JobFormModal>
    </Spin>
  );
};

export default ScheduleJobForm;
