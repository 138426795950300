import React, { Fragment } from 'react';
import { Avatar, Dropdown, Menu, Space, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import NotificationIcon from './NotificationIcon';
import { AuthContext } from '@/pages/AuthContext';

import styles from './index.module.less';

const RightContent = () => {
  const contextValue = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    if (key === 'user') {
      navigate('/user-setting');
    }
    if (key === 'logout') {
      contextValue.logout(() => navigate('/login'));
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={'user'}>User</Menu.Item>
      <Menu.Item key={'logout'} danger>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <div className={styles['header-right-action']}>
        <Tooltip title="Message">
          <NotificationIcon />
        </Tooltip>
      </div>
      <div className={styles['header-right-action']}>
        <Space>
          <Avatar size={22} style={{ backgroundColor: '#1890FF' }} onClick={() => navigate('/user-setting')}>
            U
          </Avatar>
          <Dropdown overlay={menu}>
            <span> {contextValue.getUser()?.name}</span>
          </Dropdown>
        </Space>
      </div>
    </Fragment>
  );
};

export default RightContent;
