import React, { useState } from 'react';
import { GridContent } from '@ant-design/pro-layout';
import { Menu } from 'antd';
import BaseView from './components/base';
import PasswordView from './components/password';
import styles from './UserSetting.module.less';

const { Item } = Menu;

const UserSetting = () => {
  const menuMap = {
    base: 'Basic Setting',
    password: 'Change Password',
  };

  const [initConfig, setInitConfig] = useState({
    mode: 'inline',
    selectKey: 'base',
  });

  const getMenu = () => {
    return Object.keys(menuMap).map(item => <Item key={item}>{menuMap[item]}</Item>);
  };

  const renderChildren = () => {
    const { selectKey } = initConfig;
    switch (selectKey) {
      case 'base':
        return <BaseView />;
      case 'password':
        return <PasswordView />;
      default:
        return null;
    }
  };

  return (
    <GridContent style={{ height: '500px' }}>
      <div className={styles.main}>
        <div className={styles.leftMenu}>
          <Menu
            mode={initConfig.mode}
            selectedKeys={[initConfig.selectKey]}
            onClick={({ key }) => {
              setInitConfig({
                ...initConfig,
                selectKey: key,
              });
            }}
          >
            {getMenu()}
          </Menu>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{menuMap[initConfig.selectKey]}</div>
          {renderChildren()}
        </div>
      </div>
    </GridContent>
  );
};
export default UserSetting;
