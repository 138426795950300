import qs from 'qs';
import request from '@/utils/request';

export async function getScheduleList(params, sorters, filters) {
  const res = await request(
    `/schedules${qs.stringify(
      {
        page: params.current,
        size: params.pageSize,
        ...params,
        ...filters,
        sorters,
      },
      { addQueryPrefix: true }
    )}`
  );
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function searchSchedule(payload) {
  return request(`/schedules/search${qs.stringify(payload, { addQueryPrefix: true })}`, null, false);
}

export async function saveSchedule(payload) {
  return request(
    '/schedules',
    {
      method: 'POST',
      body: payload,
    },
    false
  );
}

export async function updateSchedule(id, payload) {
  return request(`/schedules/${id}`, {
    method: 'PATCH',
    body: payload,
  });
}

export async function scheduleCSV(payload) {
  return request(`/schedules/files/csv${qs.stringify(payload, { addQueryPrefix: true })}`);
}
