import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';

const AccountSelect = ({ onChange, value, disabled = false }) => {
  const accounts = useSelector(state => state.app.accounts);
  const [keywords, setKeywords] = useState('');
  const filterAccounts = useMemo(
    () =>
      keywords
        ? accounts.filter(a => a.active).filter(a => a.username.indexOf(keywords) !== -1)
        : accounts.filter(a => a.active),
    [keywords, accounts]
  );

  const handleSearch = val => {
    setKeywords(val);
  };

  return (
    <Select
      disabled={disabled}
      showSearch
      allowClear
      onChange={onChange}
      placeholder={'Select account'}
      onSearch={handleSearch}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      value={value}
    >
      {<Select.Option value={'automation'}>Auto select</Select.Option>}
      {filterAccounts?.map(item => (
        <Select.Option key={item.id}>
          {item.username} - {item.balanceRaw ? item.balanceRaw : `${item.firstName || ''} ${item.lastName || ''}`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AccountSelect;
