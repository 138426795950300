import { JobList, JobDetail, TourTimePointList, TourPurchasedList } from '@/pages';
import { ScheduleList, ScheduleAdd } from '@/pages/schedule';
import AvailabilityDetail from '@/pages/availability/detail';
import ForgetPassword from '@/pages/forget-password';
import ResetPassword from '@/pages/reset-password';
import Notification from '@/pages/notification';
import UserSetting from '@/pages/user-setting';
import GroupList from '@/pages/tourist-group';
import Authorized from '@/pages/Authorized';
import CronJobList from '@/pages/cron-job';
import TicketList from '@/pages/ticket';
import Setting from '@/pages/setting';
import NoMatch from '@/pages/NoMatch';
import GuideList from '@/pages/guide';
import GlobalLog from '@/pages/log';
import UserList from '@/pages/user';
import TourList from '@/pages/tour';
import Login from '@/pages/login';
import Card from '@/pages/card';
import Layout from '@/layouts';

const routes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forget-password',
    element: <ForgetPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/',
    element: (
      <Authorized>
        <Layout />
      </Authorized>
    ),
    children: [
      {
        index: true,
        element: <ScheduleList />,
      },
      {
        path: '/schedule',
        element: <ScheduleAdd />,
      },
      {
        path: '/tourist-group',
        element: <GroupList />,
      },
      {
        path: '/job',
        element: <JobList />,
        role: 'admin',
      },
      {
        path: '/job/:id',
        element: <JobDetail />,
      },
      {
        path: '/user',
        element: <UserList />,
        role: 'admin',
      },
      {
        path: '/ticket',
        element: <TicketList />,
      },
      {
        path: '/guide',
        element: <GuideList />,
      },
      {
        path: '/cron-job',
        element: <CronJobList />,
      },
      {
        path: '/card',
        element: <Card />,
      },
      {
        path: '/setting',
        element: <Setting />,
      },
      {
        path: '/tour-list',
        element: <TourList />,
      },
      {
        path: '/user-setting',
        element: <UserSetting />,
      },
      {
        path: '/global-log',
        element: <GlobalLog />,
      },
      {
        path: '/notification',
        element: <Notification />,
      },
      {
        path: '/availability/:id',
        element: <AvailabilityDetail />,
      },
      {
        path: '/tour-time-point',
        role: 'admin',
        children: [
          {
            path: ':tourId',
            element: <TourTimePointList />,
            role: 'admin',
          },
        ],
      },
      {
        path: '/report',
        element: <TourPurchasedList />,
      },
    ],
  },
  {
    path: '*',
    element: <NoMatch />,
  },
];

export default routes;
