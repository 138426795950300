import request from '@/utils/request';
import qs from 'qs';

export async function getTourTimePointList(params, sort, filter) {
  const res = await request(
    `/tour-time-points${qs.stringify(
      {
        size: params.pageSize,
        page: params.current,
        ...params,
        ...sort,
      },
      { addQueryPrefix: true }
    )}`
  );
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function saveTourTimePoint(body) {
  return request('/tour-time-points', {
    method: 'POST',
    body,
  });
}

export async function updateTourTimePoint(id, body) {
  return await request(`/tour-time-points/${id}`, {
    method: 'PATCH',
    body,
  });
}

export async function deleteTourTimePoint(id) {
  const res = await request(`/tour-time-points/${id}`, {
    method: 'DELETE',
  });
  if (res) {
    return res;
  }
  return false;
}
