import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accounts: [],
  tours: [],
  guides: [],
  cards: [],
  vendors: [],
  activeVendorId: window.localStorage.getItem('ACTIVE_VENDOR_ID'),
};

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setAppTours: (state, action) => {
      state.tours = action.payload;
    },
    setAppGuides: (state, action) => {
      state.guides = action.payload;
    },
    setAppCards: (state, action) => {
      state.cards = action.payload;
    },
    setVendors: (state, action) => {
      state.vendors = action.payload;
    },
    setActiveVendorId: (state, action) => {
      window.localStorage.setItem('ACTIVE_VENDOR_ID', action.payload);
      state.activeVendorId = action.payload;
    },
  },
});

export const { setAppAccounts, setAppTours, setAppGuides, setAppCards, setVendors, setActiveVendorId } =
  AppSlice.actions;

export default AppSlice.reducer;
