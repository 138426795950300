import React, { useContext } from 'react';
import { message } from 'antd';
import ProForm, { ProFormText } from '@ant-design/pro-form';

import styles from './BaseView.module.less';
import { AuthContext } from '@/pages/AuthContext';
import { changePassword } from '@/services/userAPI';

const PasswordView = () => {
  const auth = useContext(AuthContext);

  const handleFinish = async values => {
    const user = auth.getUser();
    const res = await changePassword(user.id, values);
    if (res) {
      message.success('success');
    }
  };
  return (
    <div className={styles.BaseView}>
      <>
        <div className={styles.left}>
          <ProForm
            layout="vertical"
            onFinish={handleFinish}
            submitter={{
              resetButtonProps: {
                style: {
                  display: 'none',
                },
              },
              submitButtonProps: {
                children: 'change password',
              },
            }}
            hideRequiredMark
          >
            <ProFormText.Password
              width="md"
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'please enter your new password!',
                },
              ]}
            />
            <ProFormText.Password
              width="md"
              name="passwordConfirmation"
              label="Password Confirmation"
              rules={[
                {
                  required: true,
                  message: 'please confirm your password!',
                },
              ]}
            />
          </ProForm>
        </div>
      </>
    </div>
  );
};

export default PasswordView;
