import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Authorized = ({ children }) => {
  const location = useLocation();
  return (
    <AuthContext.Consumer>
      {args => {
        if (args.hasAuth()) {
          return children;
        }
        return <Navigate to="/login" state={{ from: location }} />;
      }}
    </AuthContext.Consumer>
  );
};

export default Authorized;
