import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Switch, Checkbox } from 'antd';
import AccountCard from './components/AccountCard';
import * as SettingsAPI from '@/services/settingsAPI';
import { useSelector } from 'react-redux';

const FIELD_TYPES = {
  SWITCH: 'switch',
  CHECKBOX: 'checkbox',
  INPUT: 'input',
  EMAIL: 'email',
};

const Setting = () => {
  const [form] = Form.useForm();

  const [settings, setSettings] = useState({});
  const [change, setChange] = useState(false);

  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const activeVendorId = useSelector(state => state.app.activeVendorId);

  useEffect(() => {
    fetchSettings({ vendorId: activeVendorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  const fetchSettings = async val => {
    setLoading(true);
    const response = await SettingsAPI.get(val);
    if (response) {
      setSettings(response || {});
      // convert to map
      const formObj = {};
      response.forEach(setting => {
        if (setting.type === FIELD_TYPES.SWITCH || setting.type === FIELD_TYPES.CHECKBOX) {
          formObj[setting.name] = Number(setting.value);
        } else {
          formObj[setting.name] = setting.value;
        }
      });
      form.setFieldsValue(formObj || {});
      setLoading(false);
    }
  };

  const handleFormFinish = async values => {
    setSubmitting(true);
    settings.forEach(setting => {
      const { name } = setting;
      setting.value = values[name];
    });
    const response = await SettingsAPI.updateList(settings);
    if (response) {
      setSubmitting(false);
      message.success('Saved Success');
      setChange(false);
    }
  };

  const onValuesChange = (changeValues, values) => {
    const keys = Object.keys(values);
    setChange(keys.some(key => values[key] !== settings[key]));
  };

  const renderFormItem = setting => {
    switch (setting.type) {
      case FIELD_TYPES.SWITCH:
        return (
          <Form.Item label={setting.label} valuePropName="checked" name={setting.name} key={setting.name}>
            <Switch />
          </Form.Item>
        );
      case FIELD_TYPES.CHECKBOX:
        return (
          <Form.Item label={setting.label} valuePropName="checked" name={setting.name} key={setting.name}>
            <Checkbox />
          </Form.Item>
        );
      case FIELD_TYPES.EMAIL:
        return (
          <Form.Item
            label={setting.label}
            name={setting.name}
            rules={[
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
            key={setting.name}
          >
            <Input />
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={setting.label} name={setting.name} key={setting.name}>
            <Input />
          </Form.Item>
        );
    }
  };

  return (
    <Card bordered>
      <Row>
        <Col offset={2} span={10} xl={4}>
          <Card title="General Setting" bordered={false} loading={loading}>
            <Form layout="vertical" form={form} onFinish={handleFormFinish} onValuesChange={onValuesChange}>
              {settings && settings.length > 0 && settings.map(setting => renderFormItem(setting))}

              <Form.Item>
                <div style={{ color: '#ff4d4f', height: '22px', marginBottom: '5px' }}>
                  {change && 'Please save to update the setting'}
                </div>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col offset={2} span={20} xl={16}>
          <AccountCard />
        </Col>
      </Row>
    </Card>
  );
};

export default Setting;
