import React, { useContext, useState } from 'react';
import { Button, Form, Input, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import * as LoginAPI from '@/services/loginAPI';
import { AuthContext } from '@/pages/AuthContext';

import styles from './Form.module.css';

const FormItem = Form.Item;
const LoginForm = () => {
  const navigate = useNavigate();
  const contextValue = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState();

  const onFinish = async values => {
    const res = await LoginAPI.login(values);
    if (res && res.token) {
      setErrorMessage(null);
      contextValue.login(res.token, res.user, () => navigate('/'));
    } else if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <div className={styles.form}>
      <h2>Sign in</h2>
      <p className={styles['signup-message']}>
        Please sign in to your personal account if you want to use all our premium products
      </p>
      <Form onFinish={onFinish} layout={'vertical'}>
        <FormItem
          label={'Your Email'}
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="Enter your email" />
        </FormItem>
        <FormItem
          label={'Your Password'}
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password size="large" placeholder="Enter your password" />
        </FormItem>
        {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Link className={styles.forgetPassword} to="/forget-password">
            Forgot password
          </Link>
        </Form.Item>
        <FormItem style={{ marginTop: '0.4rem' }}>
          <Button htmlType="submit" type="primary" size="large" block className={styles['btn-login']}>
            SIGN IN
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default LoginForm;
