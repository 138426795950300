import React, { useEffect, useRef, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { getList, getById, update } from '@/services/cronJobAPI';
import columns from './cron.job.columns';
import { useSelector } from 'react-redux';
import { Descriptions, message, Modal } from 'antd';
import moment from 'moment';
import { ModalForm, ProFormSelect, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import { getCronOptions } from '../../utils/cron';

const CronJobList = () => {
  const actionRef = useRef();
  const activeVendorId = useSelector(state => state.app.activeVendorId);

  const [job, setJob] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const getCronJobData = async id => {
    const data = await getById(id);
    setJob(data);
  };

  const editSubmit = async values => {
    const hide = message.loading('processing');
    const res = await update(job.id, {
      ...values,
    });
    hide();
    if (res && res.id) {
      setEditModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  };

  const action = {
    title: 'Action',
    valueType: 'option',
    width: 200,
    render: (text, record) => [
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        key={`view-${record.id}`}
        onClick={async () => {
          await getCronJobData(record.id);
          setViewModalVisible(true);
        }}
      >
        View
      </a>,
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        key={`edit-${record.id}`}
        onClick={async () => {
          await getCronJobData(record.id);
          setEditModalVisible(true);
        }}
      >
        Edit
      </a>,
    ],
  };

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [activeVendorId]);

  const handleSearch = async (params, sort, filter) => {
    return await getList({ vendorId: activeVendorId });
  };

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        actionRef={actionRef}
        tableAlertRender={false}
        request={handleSearch}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={false}
        pagination={false}
        dateFormatter="string"
        headerTitle="Cron Job List"
        options={false}
      />

      {viewModalVisible && job && (
        <Modal
          title="View Cron Job"
          open={viewModalVisible}
          onOk={() => {
            setViewModalVisible(false);
          }}
          onCancel={() => {
            setViewModalVisible(false);
          }}
        >
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Name">{job.name}</Descriptions.Item>
            <Descriptions.Item label="Type">{job.type}</Descriptions.Item>
            <Descriptions.Item label="Status">{job.active ? 'Active' : 'Deactive'}</Descriptions.Item>
            <Descriptions.Item label="Running">{job.running ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Last Run Date">
              {moment(job.lastDate).format('YYYY-MM-DD HH:MM:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Next Run Date">
              {moment(job.nextDate).format('YYYY-MM-DD HH:MM:ss')}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}

      {editModalVisible && job && (
        <ModalForm
          title="Edit Cron Job"
          width="400px"
          visible={editModalVisible}
          onVisibleChange={visible => {
            setEditModalVisible(visible);
          }}
          onFinish={editSubmit}
          initialValues={job}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="name"
            label="Name"
            readonly={true}
          />
          <ProFormSelect options={getCronOptions()} width="md" name="cron" label="Cron Expression" />
          <ProFormSwitch
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="active"
            label="Active"
          />
        </ModalForm>
      )}
    </PageContainer>
  );
};

export default CronJobList;
