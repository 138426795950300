import { FormatDateTime } from '@/components/FormatDateTime';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import React from 'react';
const { Text } = Typography;

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 90,
    hideInSearch: true,
    render: (id, record) => (
      <Link to={`/schedule?catalogId=${record.tourId}&fromDate=${record.fromDate}`}>
        <Text style={{ width: 64 }} ellipsis={{ tooltip: id }} type="warning">
          {id}
        </Text>
      </Link>
    ),
  },
  {
    title: 'Product',
    dataIndex: 'tourShortName',
    hideInSearch: true,
    render: (shortName, record) => shortName || record.tourName,
  },
  {
    width: 120,
    title: 'Ticket Date',
    dataIndex: 'fromDate',
    defaultSortOrder: 'descend',
    sorter: true,
    hideInSearch: true,
    render: value => <FormatDateTime time={value}></FormatDateTime>,
  },
  {
    title: 'Max Tickets',
    dataIndex: 'maxTicketsPerDay',
    hideInSearch: true,
  },
  {
    title: 'Tickets purchased',
    dataIndex: 'fragments',
    hideInSearch: true,
    render: fragments => {
      if (fragments && fragments.length > 0) {
        const number = fragments
          .filter(f => f.purchaseStatus === 'Purchased' && !f.dryRun)
          .reduce((prev, curr) => {
            prev += curr.purchasedClientTicketsNumber;
            return prev;
          }, 0);
        return `${number}T`;
      }
      return `0T`;
    },
  },
  {
    title: 'Tickets to be purchased',
    dataIndex: 'fragments',
    hideInSearch: true,
    render: (fragments, record) => {
      if (fragments && fragments.length > 0) {
        const number = fragments
          .filter(f => f.purchaseStatus === 'Purchased' && !f.dryRun)
          .reduce((prev, curr) => {
            prev += curr.purchasedClientTicketsNumber;
            return prev;
          }, 0);
        return `${record.maxTicketsPerDay - number}T`;
      }
      return `${record.maxTicketsPerDay}T`;
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    filters: [
      { text: 'Ongoing', value: 'Ongoing' },
      { text: 'Complete', value: 'Complete' },
    ],
    defaultFilteredValue: ['Ongoing', 'Complete'],
    hideInSearch: true,
    render: (_, record) => {
      const fragments = record.fragments;
      if (fragments && fragments.length > 0) {
        const exist = fragments.find(f =>
          ['Pending Purchase', 'Purchasing', 'Holding', 'Checking', 'Paying'].includes(f.purchaseStatus)
        );
        if (exist) {
          return 'Ongoing';
        } else {
          return 'Complete';
        }
      }
      return 'Complete';
    },
  },
  {
    title: 'Finished',
    hideInSearch: true,
    render: (_, record) => {
      let ticketsNumber = 0;
      if (record.fragments && record.fragments.length > 0) {
        ticketsNumber = record.fragments
          .filter(f => f.purchaseStatus === 'Purchased' && !f.dryRun)
          .map(f => f.purchasedClientTicketsNumber)
          .reduce((s, a) => s + a, 0);
      }
      return ticketsNumber >= record.maxTicketsPerDay ? 'True' : 'False';
    },
  },
  {
    title: 'Ticket Date',
    key: 'fromDate',
    valueType: 'dateRange',
    hideInTable: true,
  },
];
