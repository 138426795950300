import request from '@/utils/request';
import qs from 'qs';

export async function getTourPurchasedList(params, sort, filter) {
  return request(
    `/reports/tour/purchased${qs.stringify(
      {
        ...params,
      },
      { addQueryPrefix: true }
    )}`
  );
}
