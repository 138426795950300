import { Form, Modal, Row, Select, Switch, TimePicker } from 'antd';
import { useState, useCallback, useContext } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { VendorType } from '@/constants/vendor.type';
import { AuthContext } from '@/pages/AuthContext';
import { naturalNumber } from '@/utils/number';
import AccountSelect from '../AccountSelect';
import { useSelector } from 'react-redux';

const Option = Select;

const JobFormModal = ({ visible, setVisible, groups, schedule, onFinish, useTouristList }) => {
  const authContext = useContext(AuthContext);

  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const [form] = Form.useForm();
  const [useTimeRange, setUseTimeRange] = useState(false);
  const [touristNumber, setTouristNumber] = useState(0);

  const handleValuesChange = useCallback(
    async values => {
      if (useTouristList) {
        if (values.touristGroups && values.touristGroups.length > 0) {
          const items = groups.filter(g => values.touristGroups.includes(g.id)).map(g => g.touristCount);
          const minNumber = Math.min(...items);
          form.setFieldsValue({
            clientTicketsNumber: minNumber,
          });
          setTouristNumber(minNumber);
        } else {
          form.setFieldsValue({
            clientTicketsNumber: 0,
          });
          setTouristNumber(0);
        }
      }
    },
    [useTouristList, form, groups, setTouristNumber]
  );

  const fromTimeChange = options => {
    if (options.includes('all')) {
      form.setFieldsValue({
        time: schedule?.tour?.timePoints?.map(item => item.timePoint.substring(0, 5)),
      });
    }
  };

  return (
    <Modal
      title="New Available Date"
      maskClosable={false}
      open={visible}
      onOk={() => form.submit()}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      okText="Add"
    >
      <Form
        layout={'vertical'}
        form={form}
        onFinish={values => {
          onFinish(values);
          form.resetFields();
        }}
        onValuesChange={handleValuesChange}
        initialValues={{
          accountId: 'automation',
          clientTicketsNumber: useTouristList ? 0 : 1,
          compete: false,
          dryRun: false,
          jobsCount: activeVendor && activeVendor.name === VendorType.Colosseum ? 48 : 1,
        }}
      >
        <Row justify={'space-around'}>
          <Form.Item label="Use Time Range?">
            <Switch
              disabled={true}
              onChange={value => {
                setUseTimeRange(value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Compete"
            name="compete"
            valuePropName="checked"
            hidden={activeVendor.name === VendorType.EiffelTower || activeVendor.name === VendorType.Louvre}
            tooltip={{
              icon: <InfoCircleOutlined />,
              title:
                'Use high concurrent requests to compete for tickets at the time of ticket release, It’s disabled for now',
            }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Dry Run"
            name="dryRun"
            valuePropName="checked"
            tooltip={{
              icon: <InfoCircleOutlined />,
              title: 'Whether the payment is not actually performed',
            }}
          >
            <Switch />
          </Form.Item>
        </Row>
        {useTimeRange ? (
          <Form.Item
            label="Time Range"
            name="timeRange"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker.RangePicker format={'HH:mm'} hideDisabledOptions={true}></TimePicker.RangePicker>
          </Form.Item>
        ) : (
          <Form.Item
            label="Available Time"
            name="time"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={'Select time'}
              mode={'tags'}
              allowClear={true}
              onChange={fromTimeChange}
            >
              {[
                <Option key={'all'} value={'all'}>
                  {'Select All'}
                </Option>,
              ].concat(
                schedule?.tour?.timePoints?.map((item, index) => (
                  <Option key={item.id} value={item.timePoint.substring(0, 5)}>
                    {item.timePoint.substring(0, 5)} {item.type.join(',')}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Account Name" name="accountId">
          <AccountSelect />
        </Form.Item>
        {useTouristList ? (
          <Form.Item
            label="Tourist Group"
            name="touristGroups"
            rules={[
              {
                validator: (rule, value) => {
                  const selectedGroups = groups.filter(g => !!value?.find(id => id === g.id));
                  console.log(selectedGroups);
                  if (value && value.length > 0 && selectedGroups && selectedGroups.length > 0) {
                    const countSet = new Set(selectedGroups.map(g => g.touristCount));
                    console.log(countSet);
                    if (countSet.size > 1) {
                      return Promise.reject(`Group tourist count not equal ${Array.from(countSet).join(',')}`);
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject('Please select group');
                  }
                },
              },
            ]}
          >
            <Select style={{ width: '100%' }} placeholder={'Please select the group'} mode={'tags'} allowClear={true}>
              {groups.map(g => (
                <Option key={g.id} value={g.id}>
                  {g.name} | tourists {g.touristCount}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            label="How many client tickets?"
            name="clientTicketsNumber"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select style={{ width: '100%' }} placeholder={'Please select the client tickets number'}>
              {naturalNumber(
                useTouristList ? touristNumber : schedule ? schedule.tour?.maxTicketsPerAccount : 50,
                1
              ).map(i => (
                <Option key={`ticket-number-${i}`} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="How many jobs?"
          name="jobsCount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder={'Please select the jobs count'}
            disabled={activeVendor && activeVendor.name === VendorType.Colosseum && !authContext.isRole('admin')}
          >
            {naturalNumber(100, 1).map(i => (
              <Option key={`jobs-count-${i}`} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default JobFormModal;
