import { FormatDateTime } from '@/components/FormatDateTime';

export const columns = [
  {
    title: 'Product',
    dataIndex: 'productShortName',
    key: 'productShortName',
    render: (shortName, record) => shortName || record.catalogName,
  },
  {
    title: 'MaxTicketsPerAccount',
    dataIndex: 'maxTicketsPerAccount',
  },
  {
    title: 'Latest Availability',
    dataIndex: 'latestDate',
    key: 'latestDate',
    render: t => <FormatDateTime time={`${t}`}></FormatDateTime>,
  },
  {
    title: 'Latest Release',
    dataIndex: 'latestReleaseDate',
    key: 'latestReleaseDate',
    render: t => <FormatDateTime time={`${t}`}></FormatDateTime>,
  },
  {
    title: 'Last Check Time',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    render: vendor => (vendor ? vendor.name : 'Unknow'),
  },
];
