import { FormatDateTime } from '@/components/FormatDateTime';

const columns = [
  {
    dataIndex: 'index',
    valueType: 'index',
    width: 48,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    hideInSearch: true,
  },
  {
    title: 'Tour',
    dataIndex: ['tour', 'catalogName'],
    hideInSearch: true,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Tourist Count',
    dataIndex: 'touristCount',
    hideInSearch: true,
  },
  {
    title: 'Jobs Count',
    dataIndex: 'jobCount',
    hideInSearch: true,
  },
  {
    title: 'Is Used',
    dataIndex: 'isUsed',
    valueEnum: {
      0: {
        text: 'False',
      },
      1: {
        text: 'True',
      },
    },
    hideInSearch: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    sorter: true,
    hideInSearch: true,
    render: t => <FormatDateTime time={t}></FormatDateTime>,
  },
  {
    title: 'Search',
    hideInTable: true,
    dataIndex: 'keywords',
  },
];

export default columns;
