import request from '@/utils/request';
import qs from 'qs';

export async function getTicketList(params) {
  const res = await request(`/tickets${qs.stringify(params, { addQueryPrefix: true })}`);
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}
