import React, { useRef, useState, useEffect } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { ModalForm, ProFormField, ProFormText, ProFormUploadDragger } from '@ant-design/pro-form';
import { deleteGuide, getGuideList, saveGuide, updateGuide, importGuide } from '@/services/guideAPI';
import { Button, message, Popconfirm } from 'antd';
import { DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import columns from './guide.columns';
import styles from './index.module.less';
import { useSelector } from 'react-redux';

const GuideList = () => {
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const actionRef = useRef();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const addSubmit = async values => {
    const hide = message.loading('processing');
    const res = await saveGuide({ ...values, vendorType: activeVendor.name });
    hide();
    if (res && res.id) {
      setShowAddForm(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  const editSubmit = async values => {
    const hide = message.loading('processing');
    const res = await updateGuide(currentRow.id, {
      ...values,
    });
    hide();
    if (res && res.id) {
      setShowEditForm(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  const deleteSubmit = async values => {
    const hide = message.loading('processing');
    const success = await deleteGuide(values.id);
    hide();
    if (success) {
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  };

  const importSubmit = async values => {
    const hide = message.loading('processing');
    const body = new FormData();
    values.files.forEach(f => {
      body.append('files', f.originFileObj);
    });
    const res = await importGuide(body);
    hide();
    if (res && res.message) {
      setErrorMessage(res.message);
    } else {
      setShowImportForm(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  };

  const fetchGuides = async params => {
    return getGuideList({ ...params, vendorId: activeVendorId });
  };

  useEffect(() => {
    if (activeVendorId && actionRef.current) {
      actionRef.current.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  const action = {
    title: 'Action',
    valueType: 'option',
    render: (text, record) => [
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        key={`edit-${record.id}`}
        onClick={() => {
          setCurrentRow({ ...record });
          setShowEditForm(true);
        }}
      >
        Edit
      </a>,
      <Popconfirm
        key={`delete-${record.id}`}
        title="Are you sure to delete this guide?"
        onConfirm={() => deleteSubmit(record)}
        okText="Yes"
        cancelText="No"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          key={`delete-${record.id}`}
          onClick={() => {
            setCurrentRow(record);
          }}
        >
          Delete
        </a>
      </Popconfirm>,
    ],
  };

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        actionRef={actionRef}
        request={fetchGuides}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={{
          labelWidth: 'auto',
          searchText: 'Search',
          className: styles.search,
        }}
        form={{}}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        headerTitle="Guide List"
        options={false}
        toolBarRender={() => [
          <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => setShowAddForm(true)}>
            Add
          </Button>,
          <Button key="button" icon={<UploadOutlined />} type="default" onClick={() => setShowImportForm(true)}>
            Upload
          </Button>,
        ]}
      />
      {showAddForm && (
        <ModalForm
          title="Add Guide Information"
          width="400px"
          visible={showAddForm}
          onVisibleChange={visible => {
            setShowAddForm(visible);
            if (!visible) {
              setErrorMessage(null);
            }
          }}
          onFinish={addSubmit}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="firstName"
            label="First Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="lastName"
            label="Last Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="licenceNumber"
            label="Licence Number"
          />
          {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        </ModalForm>
      )}
      {showEditForm && (
        <ModalForm
          title="Edit Guide Information"
          width="400px"
          visible={showEditForm}
          onVisibleChange={visible => {
            setShowEditForm(visible);
            if (!visible) {
              setErrorMessage(null);
            }
          }}
          onFinish={editSubmit}
          initialValues={currentRow}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="firstName"
            label="First Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="lastName"
            label="Last Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="licenceNumber"
            label="Licence Number"
          />
          {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        </ModalForm>
      )}
      {showImportForm && (
        <ModalForm
          title="Import Guide"
          width="400px"
          visible={showImportForm}
          onVisibleChange={visible => {
            setShowImportForm(visible);
            if (!visible) {
              setErrorMessage(null);
            }
          }}
          onFinish={importSubmit}
        >
          <ProFormField>
            <Button
              key="button"
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => window.open(process.env.REACT_APP_API_BASE_URL + '/guides/import/template', '_blank')}
            >
              Download Template
            </Button>
          </ProFormField>

          <ProFormUploadDragger
            name="files"
            title="Click or drag files to this area to upload"
            description="Support single or batch upload"
            max={10}
            rules={[
              {
                required: false,
              },
            ]}
            accept=".csv"
            fieldProps={{
              beforeUpload: file => {
                return false;
              },
            }}
          ></ProFormUploadDragger>
          {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        </ModalForm>
      )}
    </PageContainer>
  );
};

export default GuideList;
