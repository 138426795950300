import { FormatDateTime } from '@/components/FormatDateTime';
import { Typography } from 'antd';
const { Text } = Typography;

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    hideInSearch: true,
    render: value => (
      <Text style={{ width: 64 }} ellipsis={{ tooltip: value }} type="warning">
        {value}
      </Text>
    ),
  },
  {
    title: 'Time Point',
    dataIndex: 'timePoint',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    hideInSearch: true,
    render: arr => (arr ? arr.join(',') : ''),
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    hideInSearch: true,
    render: value => <FormatDateTime time={value}></FormatDateTime>,
  },
];
