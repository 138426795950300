import request from '@/utils/request';

export async function createVendor(payload) {
  return request(
    '/vendors',
    {
      method: 'POST',
      body: payload,
    },
    false
  );
}

export async function getVendors() {
  return request(`/vendors`);
}

export async function updateVendor(id, payload) {
  return request(`/vendors/${id}`, { method: 'PATCH', body: payload });
}
