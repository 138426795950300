import request from '@/utils/request';
import qs from 'qs';

export async function getCardById(id) {
  const res = await request(`/credit-cards/${id}`);
  return res;
}

export async function getCardList(params, sort, filter) {
  const res = await request(
    `/credit-cards${qs.stringify(
      {
        size: params.pageSize,
        page: params.current,
        ...params,
        ...sort,
      },
      { addQueryPrefix: true }
    )}`
  );
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function saveCard(body) {
  return request(
    '/credit-cards',
    {
      method: 'POST',
      body,
    },
    false
  );
}

export async function updateCard(id, body) {
  return await request(
    `/credit-cards/${id}`,
    {
      method: 'PATCH',
      body,
    },
    false
  );
}

export async function deleteCard(id) {
  const res = await request(`/credit-cards/${id}`, {
    method: 'DELETE',
  });
  if (res && res.id) {
    return res;
  }
  return false;
}
