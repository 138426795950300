import React, { useEffect, useRef } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { useSearchParams } from 'react-router-dom';
import { getTicketList } from '@/services/ticketAPI';
import columns from './ticket.columns';
import styles from './index.module.less';
import { useSelector } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const TicketList = () => {
  const actionRef = useRef();
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const accounts = useSelector(state => state.app.accounts);
  const [searchParams, setSearchParams] = useSearchParams({});

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  // eslint-disable-next-line no-unused-vars
  const [_, setSelectedAll] = React.useState(false);

  const action = {
    title: 'Action',
    valueType: 'option',
    width: 200,
    render: (text, record) =>
      record.pdfUrl
        ? [
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            <a
              key={`download-${record.id}`}
              onClick={() => {
                window.open(`${process.env.REACT_APP_API_BASE_URL}/tickets/download/${record.id}`);
              }}
            >
              Download
            </a>,
            ...record.pdfUrl.split(',').map((url, index) => (
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <a
                key={`view-${record.id}-${index}`}
                onClick={() => {
                  window.open(`${process.env.REACT_APP_API_BASE_URL}/tickets/view${url}`);
                }}
              >
                PDF {index + 1}
              </a>
            )),
          ]
        : [],
  };

  const handleSearch = async (params, sort, filter) => {
    const { pageSize, current, ...otherParams } = params;

    const newParams = {
      size: pageSize,
      page: current,
      ...otherParams,
      start: otherParams.datetime ? otherParams.datetime[0] : null,
      end: otherParams.datetime ? otherParams.datetime[1] : null,
    };

    const urlParams = {};
    searchParams.forEach((v, k) => (urlParams[k] = v));
    const combinParams = { ...urlParams, ...newParams, ...sort, vendorId: activeVendorId };
    Object.keys(combinParams).forEach(key => {
      if (!combinParams[key]) {
        delete combinParams[key];
      }
    });

    setSearchParams(combinParams);
    return await getTicketList(combinParams);
  };

  const handleDownloadBatch = async () => {
    const ids = selectedRows.map(row => row.id);
    window.open(process.env.REACT_APP_API_BASE_URL + '/tickets/download/batch?ids=' + ids.join(','));
  };

  const rowSelection = {
    alwaysShowAlert: false,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    onSelectAll: selected => {
      if (selected) {
        setSelectedAll(true);
      } else {
        setSelectedRowKeys([]);
        setSelectedAll(false);
      }
    },
    selections: [
      {
        key: 'SelectAll',
        text: 'Select All',
        onSelect: changeableRowKeys => {
          setSelectedRowKeys(changeableRowKeys);
          setSelectedAll(true);
        },
      },
      {
        key: 'SelectNone',
        text: 'Select None',
        onSelect: () => {
          setSelectedRowKeys([]);
          setSelectedAll(false);
        },
      },
    ],
  };

  useEffect(() => {
    columns[7].filters = accounts
      .filter(a => a.active)
      .map(a => ({
        text: a.username,
        value: a.id,
      }));
    actionRef.current.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        actionRef={actionRef}
        rowSelection={rowSelection}
        tableAlertRender={false}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<DownloadOutlined />}
            type="primary"
            onClick={handleDownloadBatch}
            disabled={!selectedRowKeys || selectedRowKeys.length === 0}
          >
            Download All
          </Button>,
        ]}
        request={handleSearch}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={{
          labelWidth: 'auto',
          searchText: 'Search',
          className: styles.search,
        }}
        form={{
          initialValues: {
            keywords: searchParams.get('keywords'),
          },
        }}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        headerTitle="Ticket List"
        options={false}
      />
    </PageContainer>
  );
};

export default TicketList;
