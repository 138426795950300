import React from 'react';
import { Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FormatDateTime } from '@/components/FormatDateTime';
const { Text } = Typography;

const columns = [
  {
    title: 'client',
    dataIndex: 'clientName',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'pdfUrl',
    dataIndex: 'pdfUrl',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Account',
    dataIndex: ['account', ['username']],
    hideInSearch: true,
  },
  {
    title: 'Ticket Type',
    dataIndex: 'service',
    hideInSearch: true,
  },
  {
    title: 'Ticket Time',
    dataIndex: 'datetime',
    render: t => <FormatDateTime time={t} isUtc={true} format={'MMM DD ,HH:mm'}></FormatDateTime>,
    hideInSearch: true,
  },
  {
    title: 'Quantity',
    dataIndex: 'pax',
    hideInSearch: true,
  },
  {
    title: () => (
      <span style={{ position: 'relative' }}>
        PNR{' '}
        <Tooltip title="Passenger Name Record">
          <InfoCircleOutlined
            style={{
              position: 'absolute',
              top: '2px',
              right: '-10px',
              width: '3px',
              height: '3px',
            }}
          />
        </Tooltip>
      </span>
    ),
    dataIndex: 'code',
    hideInSearch: true,
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    hideInSearch: true,
    render: cost => `€ ${cost}`,
  },
  {
    title: 'Booked At',
    dataIndex: 'bookingDatetime',
    hideInSearch: true,
    render: t => <FormatDateTime time={t} isUtc={true}></FormatDateTime>,
  },
  {
    title: 'Schedule ID',
    dataIndex: ['schedule', 'id'],
    key: 'scheduleId',
    render: (scheduleId, record) =>
      record.schedule ? (
        <Link to={`/schedule?catalogId=${record.schedule.tourId}&fromDate=${record.schedule.fromDate}`}>
          <Text style={{ width: 64 }} ellipsis={{ tooltip: scheduleId }} type="warning">
            {scheduleId}
          </Text>
        </Link>
      ) : (
        ''
      ),
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    render: vendor => (vendor ? vendor.name : 'Unknow'),
    hideInSearch: true,
  },
  {
    title: 'Search',
    hideInTable: true,
    dataIndex: 'keywords',
  },
  {
    title: 'Ticket time',
    hideInTable: true,
    valueType: 'dateTimeRange',
    dataIndex: 'datetime',
  },
];

export default columns;
