import React, { useContext, useEffect, useState } from 'react';
import ScheduledTable from './ScheduledTable';
import * as SettingsAPI from '@/services/settingsAPI';
import { AuthContext } from '@/pages/AuthContext';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { message } from 'antd';

const scheduleDefaultValues = { maxTicketsPerAccount: 5 };

const ScheduleList = () => {
  const accounts = useSelector(state => state.app.accounts);
  const activeVendorId = useSelector(state => state.app.activeVendorId);
  const [newScheduleVisible, setNewScheduleVisible] = useState(false);
  const [scheduleInitialValue, setScheduleInitialValue] = useState(scheduleDefaultValues);
  const [socket, setSocket] = useState(null);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchSettings({ vendorId: activeVendorId });
  }, [activeVendorId]);

  useEffect(() => {
    const client = io(`${process.env.REACT_APP_SOCKET_IO_BASE_URL}/dashboard`, {
      transports: ['websocket'],
      auth: {
        token: authContext.token(),
      },
    });
    client.on('disconnect', reason => {
      setSocket(null);
    });
    setSocket(client);
    return () => {
      if (client) {
        client.close();
        delete client.io.nsps['/dashboard'];
        setSocket(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSettings = async param => {
    const response = await SettingsAPI.get(param);
    if (response) {
      scheduleDefaultValues.maxTicketsPerAccount = response.maxTicketsPerAccount;
      setScheduleInitialValue({ maxTicketsPerAccount: response.maxTicketsPerAccount });
    }
  };

  const handleNewScheduleVisible = value => {
    if (value) {
      if (accounts && accounts.length > 0) {
        setNewScheduleVisible(true);
      } else {
        message.warning('Please add vendor accounts');
      }
    } else {
      setNewScheduleVisible(false);
    }
  };

  return (
    <ScheduledTable
      newScheduleVisible={newScheduleVisible}
      handleNewScheduleVisible={handleNewScheduleVisible}
      onNewScheduleCancel={() => {
        setNewScheduleVisible(false);
        setScheduleInitialValue(scheduleDefaultValues);
      }}
      newScheduleValues={scheduleInitialValue}
      socket={socket}
    />
  );
};

export default ScheduleList;
