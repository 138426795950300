import React, { useRef, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { deleteUser, getUserList, saveUser, updateUser } from '@/services/userAPI';
import { Button, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import columns from './user.columns';
import styles from './index.module.less';
import { splitName } from '@/utils/name';
import { AuthContext } from '@/pages/AuthContext';

const UserList = () => {
  const contextValue = React.useContext(AuthContext);
  const currentUser = contextValue.getUser();
  const actionRef = useRef();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const addSubmit = async values => {
    const hide = message.loading('processing');
    const res = await saveUser({ ...values, name: `${values.firstName} ${values.lastName}` });
    hide();
    if (res && res.id) {
      setShowAddForm(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  const editSubmit = async values => {
    const hide = message.loading('processing');
    const res = await updateUser(currentRow.id, {
      ...values,
      name: `${values.firstName} ${values.lastName}`,
    });
    hide();
    if (res && res.id) {
      setShowEditForm(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } else if (res && res.message) {
      setErrorMessage(res.message);
    }
  };

  const deleteSubmit = async values => {
    const hide = message.loading('processing');
    const success = await deleteUser(values.id);
    hide();
    if (success) {
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  };

  const action = {
    title: 'Action',
    valueType: 'option',
    render: (text, record) => [
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <a
        onClick={() => {
          const [firstName, lastName] = splitName(record.name);
          setCurrentRow({ ...record, firstName, lastName, email: record.email.email });
          setShowEditForm(true);
        }}
      >
        Edit
      </a>,
      <Popconfirm
        title="Are you sure to delete this user?"
        onConfirm={() => deleteSubmit(record)}
        okText="Yes"
        cancelText="No"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            setCurrentRow(record);
          }}
        >
          Delete
        </a>
      </Popconfirm>,
    ],
  };

  return (
    <PageContainer>
      <ProTable
        style={{ minWidth: 1000 }}
        columns={columns.concat(action)}
        actionRef={actionRef}
        request={getUserList}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        search={{
          labelWidth: 'auto',
          searchText: 'Search',
          className: styles.search,
        }}
        form={{}}
        pagination={{
          pageSize: 10,
        }}
        dateFormatter="string"
        headerTitle="User List"
        options={false}
        toolBarRender={() => [
          <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => setShowAddForm(true)}>
            Add
          </Button>,
        ]}
      />
      {showAddForm && (
        <ModalForm
          title="Add User"
          width="400px"
          visible={showAddForm}
          onVisibleChange={visible => {
            setShowAddForm(visible);
            if (!visible) {
              setErrorMessage(null);
            }
          }}
          initialValues={{
            role: 'coordinator',
          }}
          onFinish={addSubmit}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
            width="md"
            name="email"
            label="Email"
          />
          <ProFormText.Password
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="password"
            label="Password"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="firstName"
            label="First Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="lastName"
            label="Last Name"
          />
          <ProFormSelect
            options={[
              {
                value: 'admin',
                label: 'Admin',
              },
              {
                value: 'coordinator',
                label: 'Coordinator',
              },
            ]}
            width="md"
            name="role"
            label="Role"
          />
          {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        </ModalForm>
      )}
      {showEditForm && (
        <ModalForm
          title="Edit User"
          width="400px"
          visible={showEditForm}
          onVisibleChange={visible => {
            setShowEditForm(visible);
            if (!visible) {
              setErrorMessage(null);
            }
          }}
          onFinish={editSubmit}
          initialValues={currentRow}
        >
          <ProFormText
            rules={[
              {
                required: true,
              },
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
            width="md"
            name="email"
            label="Email"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="firstName"
            label="First Name"
          />
          <ProFormText
            rules={[
              {
                required: true,
              },
            ]}
            width="md"
            name="lastName"
            label="Last Name"
          />
          <ProFormSelect
            options={[
              {
                value: 'admin',
                label: 'Admin',
              },
              {
                value: 'coordinator',
                label: 'Coordinator',
              },
            ]}
            disabled={currentUser.id === currentRow.id && currentUser.role === 'admin'}
            width="md"
            name="role"
            label="Role"
          />
          {errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}
        </ModalForm>
      )}
    </PageContainer>
  );
};

export default UserList;
