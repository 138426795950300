import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Dropdown, List, message } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { io } from 'socket.io-client';
import { AuthContext } from '@/pages/AuthContext';

import moment from 'moment';
import * as NotificationAPI from '@/services/notificationAPI';

import { howLongAgo, renderContent } from '../pages/notification/notification.columns';

import styles from './Notification.module.less';
import { Link } from 'react-router-dom';

const NotificationIcon = () => {
  const authContext = useContext(AuthContext);

  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [lastCreatedAt, setLastCreatedAt] = useState('');

  useEffect(() => {
    const client = io(`${process.env.REACT_APP_SOCKET_IO_BASE_URL}/notification`, {
      transports: ['websocket'],
      auth: {
        token: authContext.token(),
      },
    });

    if (client && client.active) {
      client.emit('subscribe', {
        systemNotification: {
          page: 1,
          size: 5,
        },
      });
      client.on('data:systemNotification', data => {
        const { total, content } = data;
        if (total > 0 && content[0]?.createdAt > lastCreatedAt) {
          message.warning('You have new system notification');
        }
        setCount(total);
        setNotifications(content || []);
        setLastCreatedAt(content[0]?.createdAt);
      });
    }

    return () => {
      if (client) {
        client.close();
        delete client.io.nsps['/notification'];
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNotificationPanel = () => {
    return (
      <List
        bordered
        split
        dataSource={notifications}
        footer={
          <Button type="link" block>
            <Link to={`/notification`}>View More</Link>
          </Button>
        }
        renderItem={item => (
          <List.Item>
            {renderContent(item)} {howLongAgo(moment(item.createdAt).toDate())}
          </List.Item>
        )}
      ></List>
    );
  };

  const handleVisibleChange = async visible => {
    if (visible && count > 0) {
      await NotificationAPI.markAsRead();
    }
  };

  return (
    <Dropdown
      overlay={renderNotificationPanel()}
      placement="bottomRight"
      trigger={['click']}
      overlayClassName={styles.container}
      onOpenChange={handleVisibleChange}
    >
      <Badge count={count}>
        <BellOutlined />
      </Badge>
    </Dropdown>
  );
};

export default NotificationIcon;
