export const findTour = (tourValue, tours) => {
  return tourValue.reduce((prve, curr) => {
    const list = prve ? prve.children : tours;
    const tour = list.find(tour => tour.catalogId === curr);
    return tour;
  }, null);
};

export const findTourForId = (catalogId, list) => {
  for (let index = 0; index < list.length; index++) {
    const item = list[index];
    if (item.catalogId === catalogId) {
      return item;
    } else if (item.children && item.children.length > 0) {
      const result = findTourForId(catalogId, item.children);
      if (result) return result;
    }
  }
  return null;
};

export const findTourValue = (catalogId, list) => {
  if (list && list.length > 0) {
    const tour = findTourForId(catalogId, list);
    if (tour) {
      if (tour.parentId) {
        return [tour.parentId, tour.catalogId];
      } else {
        return [tour.catalogId];
      }
    }
  }
  return [];
};

export const getTourTimePointType = tourId => {
  /** arean */
  if (tourId && tourId === 'F3CB77BD-6A43-108B-6723-0174490EB610') {
    return [
      {
        value: 'Weekly',
        label: 'Weekly',
      },
      {
        value: 'Monthly',
        label: 'Monthly',
      },
      {
        value: 'HalfDaily',
        label: 'HalfDaily',
      },
    ];
  } else {
    return [
      {
        value: 'Weekly',
        label: 'Weekly',
      },
      {
        value: 'Monthly',
        label: 'Monthly',
      },
    ];
  }
};

export const getTourTimePointTypeMode = tourId => {
  /** 24h  */
  if (tourId && tourId === 'B79E95CA-090E-FDA8-2364-017448FF0FA0') {
    return 'multiple';
  } else {
    return 'single';
  }
};
