import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';

const Option = Select;

const CardSelect = ({ onChange, value, disabled = false }) => {
  const cards = useSelector(state => state.app.cards);

  return (
    <Select
      disabled={disabled}
      showSearch
      allowClear
      onChange={onChange}
      placeholder={'Select card'}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      value={value}
    >
      {cards.map(item => (
        <Option key={item.id}>
          {item.name} {item.cardNumberLast4}
        </Option>
      ))}
    </Select>
  );
};

export default CardSelect;
