import request from '@/utils/request';
import qs from 'qs';

export async function getUserList(params, sort, filter) {
  const res = await request(
    `/users${qs.stringify(
      {
        size: params.pageSize,
        page: params.current,
        ...params,
        email: params?.email?.email,
        ...sort,
      },
      { addQueryPrefix: true }
    )}`
  );
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function saveUser(body) {
  return request(
    '/users',
    {
      method: 'POST',
      body,
    },
    false
  );
}

export async function updateUser(id, body) {
  return await request(
    `/users/${id}`,
    {
      method: 'PATCH',
      body,
    },
    false
  );
}

export async function deleteUser(id) {
  const res = await request(`/users/${id}`, {
    method: 'DELETE',
  });
  if (res && res.id) {
    return res;
  }
  return false;
}

export async function changePassword(id, body) {
  const res = await request(`/users/password/${id}`, {
    method: 'PATCH',
    body,
  });
  if (res && res.id) {
    return true;
  }
  return false;
}
