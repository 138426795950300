import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Descriptions, Table, Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import * as LogAPI from '@/services/logAPI';
import * as FragmentAPI from '@/services/fragmentAPI';

import DetailModal from '@/components/log/DetailModal';

import { useSearchParams } from 'react-router-dom';

const { TabPane } = Tabs;

const JobDetail = () => {
  const [loading, setLoading] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [job, setJob] = React.useState({});
  const [logs, setLogs] = React.useState([]);
  const [logDetail, setLogDetail] = React.useState({});
  const [searchParams, setSearchParams] = useSearchParams({});
  let { id } = useParams();

  useEffect(() => {
    fetchJob();
    fetchScheduleLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchJob = async () => {
    setLoading(true);
    const response = await FragmentAPI.getById(id);
    if (response) {
      setJob(response || {});
      setLoading(false);
    }
  };

  const fetchScheduleLogs = async () => {
    setLoading(true);
    const response = await LogAPI.getByTraceId(id);
    if (response) {
      setLogs(response || []);
      setLoading(false);
    }
  };

  const fetchLogDetail = async (logId, createdAt) => {
    const response = await LogAPI.getById(logId, createdAt);
    if (response) {
      setLogDetail(response || {});
    }
  };

  const handleDetailVisible = record => {
    setDetailVisible(true);
    fetchLogDetail(record.id, record.createdAt);
  };

  const handleTabsChange = tab => {
    setSearchParams({ view: tab });
  };

  const renderTickets = job => {
    let str = `${job.clientTicketsNumber}T`;
    return str;
  };

  const renderScheduleStatus = status => {
    let badge;
    switch (status) {
      case 'Pending Purchase':
        badge = <Badge status="default" text={status} />;
        break;
      case 'Purchasing':
      case 'Holding':
      case 'Checking':
      case 'Paying':
        badge = <Badge status="processing" text={status} />;
        break;
      case 'Canceled':
        badge = <Badge status="warning" text={status} />;
        break;
      case 'Failed Purchase':
        badge = <Badge status="error" text={status} />;
        break;
      case 'Purchased':
        badge = <Badge status="success" text={status} />;
        break;
      default:
        badge = <Badge status="default" text={status} />;
        break;
    }
    return badge;
  };

  return (
    <Card bordered loading={loading}>
      <Tabs defaultActiveKey={searchParams.get('view')} onChange={handleTabsChange}>
        <TabPane tab="Job Information" key="information">
          <Descriptions style={{ marginLeft: '20px' }} bordered column={2}>
            <Descriptions.Item label="Product">{job.schedule && job.schedule.tourName}</Descriptions.Item>
            <Descriptions.Item label="Ticket Time">
              {job.toTime && job.fromTime
                ? `${job.fromTime.substring(0, 5)} - ${job.toTime.substring(0, 5)}`
                : `${job.fromTime ? job.fromTime.substring(0, 5) : ''}`}
            </Descriptions.Item>

            <Descriptions.Item label="Account">{job && job.account && job.account.username}</Descriptions.Item>
            <Descriptions.Item label="Guide">
              {job.guide ? `${job.guide.firstName} ${job.guide.lastName} (${job.guide.licenceNumber})` : ''}
            </Descriptions.Item>

            <Descriptions.Item label="Tickets">{`${renderTickets(job)}`}</Descriptions.Item>
            <Descriptions.Item label="Purchase Status">{renderScheduleStatus(job.purchaseStatus)}</Descriptions.Item>

            <Descriptions.Item label="Fail Reason">{job.failReason}</Descriptions.Item>
            <Descriptions.Item label="Ticket time">
              {job.purchaseTime ? job.purchaseTime.substring(0, 5) : ''}
            </Descriptions.Item>

            <Descriptions.Item label="PNR">
              {job.histories && job.histories.length > 0 ? job.histories[0].pnr : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Last snapshot">
              {job.lastSnapshot ? (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <a
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_BASE_URL}/aws/view${job.lastSnapshot}`);
                  }}
                >
                  Open
                </a>
              ) : null}
            </Descriptions.Item>

            <Descriptions.Item label="Tourist Group" columns={2}>
              {job.groups?.map(group => (
                <div>{`name: ${group.name}, tourist count: ${group.touristCount}${
                  group.fragmentId === job.id ? ', used by this job' : ''
                }`}</div>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Job Index">{job ? job.jobIndex : ''}</Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab="Job Logs" key="logs">
          <Table
            loading={loading}
            dataSource={logs}
            rowKey={record => record.id}
            pagination={false}
            columns={[
              {
                title: 'Request Account',
                dataIndex: 'traceAccount',
                key: 'traceAccount',
                ellipsis: {
                  showTitle: false,
                },
              },
              {
                title: 'Request URL',
                dataIndex: 'reqUrl',
                key: 'reqUrl',
                width: 300,
              },
              {
                title: 'Method',
                dataIndex: 'reqMethod',
                key: 'reqMethod',
                width: 80,
                ellipsis: {
                  showTitle: false,
                },
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 80,
                ellipsis: {
                  showTitle: false,
                },
                render: (value, record) => {
                  if (value < 400) {
                    return <Badge status="success" text={`${value} ok`} />;
                  }
                  return <Badge status="error" text={`${value} ${record.errorMessage}`} />;
                },
              },
              {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                ellipsis: {
                  showTitle: false,
                },
              },
              {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                ellipsis: {
                  showTitle: false,
                },
              },
              {
                title: 'Action',
                key: 'action',
                width: 120,
                render: (text, record) => (
                  <Button type={'link'} onClick={() => handleDetailVisible(record)}>
                    Detail
                  </Button>
                ),
              },
            ]}
          />
        </TabPane>
      </Tabs>
      <DetailModal visible={detailVisible} handleModalCancel={() => setDetailVisible(false)} log={logDetail} />
    </Card>
  );
};

export default JobDetail;
