import request from '@/utils/request';
import qs from 'qs';

export async function getGroupById(id) {
  const res = await request(`/tourist-groups/${id}`);
  return res;
}

export async function getGroupList(params, sort, filter) {
  const res = await request(
    `/tourist-groups${qs.stringify(
      {
        size: params.pageSize,
        page: params.current,
        ...params,
        ...sort,
      },
      { addQueryPrefix: true }
    )}`
  );
  if (res && res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function saveGroup(body) {
  return request(
    '/tourist-groups',
    {
      method: 'POST',
      body,
    },
    false
  );
}

export async function updateGroup(id, body) {
  return await request(
    `/tourist-groups/${id}`,
    {
      method: 'PATCH',
      body,
    },
    false
  );
}

export async function deleteGroup(id) {
  const res = await request(`/tourist-groups/${id}`, {
    method: 'DELETE',
  });
  if (res && res.id) {
    return res;
  }
  return false;
}
