import React, { useEffect, useState, useContext } from 'react';
import * as ToursAPI from '@/services/toursAPI';
import * as AvailablityAPI from '@/services/availablityAPI';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { AuthContext } from '@/pages/AuthContext';
import ProTable from '@ant-design/pro-table';
import { columns } from './columns';
import { Button, message } from 'antd';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

const LogTable = () => {
  const navigation = useNavigate();
  let { id } = useParams();

  const activeVendorId = useSelector(state => state.app.activeVendorId);

  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshing, setRefreshing] = useState(false);
  const [tour, setTour] = useState(null);
  const authContext = useContext(AuthContext);

  const fetchAvailability = async (params, sort, filter) => {
    const { pageSize, current, ...otherParams } = params;
    if (otherParams.fromDate && otherParams.fromDate.length === 2) {
      setSearchParams({
        fromDateStart: otherParams.fromDate[0],
        fromDateEnd: otherParams.fromDate[1],
      });
    }
    return AvailablityAPI.page({
      size: pageSize,
      page: current,
      ...filter,
      fromDateStart: otherParams.fromDate ? otherParams.fromDate[0] : searchParams.get('fromDateStart'),
      fromDateEnd: otherParams.fromDate ? otherParams.fromDate[1] : searchParams.get('fromDateEnd'),
      catalogId: id,
    });
  };

  const handleRefresh = async () => {
    message.info('refresh begin');
    const res = await ToursAPI.refreshTour(id);
    if (res.ok) {
      setRefreshing(true);
    }
  };

  async function getTours(toudId) {
    const tour = await ToursAPI.getTour(toudId);
    setTour(tour);
    if (tour.catalogId === id) {
      setRefreshing(tour.refreshing);
    }
  }

  useEffect(() => {
    getTours(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (tour && tour.vendor && tour.vendor.id !== activeVendorId) {
      navigation('/tour-list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  useEffect(() => {
    const client = io(`${process.env.REACT_APP_SOCKET_IO_BASE_URL}/tour`, {
      transports: ['websocket'],
      auth: {
        token: authContext.token(),
      },
    });
    client.emit('subscribe', {
      tour: {
        id,
      },
    });
    client.on('data:tour', data => {
      if (data.tourId === id) {
        setRefreshing(false);
        message.info('refresh end');
      }
    });
    return () => {
      if (client) {
        client.close();
        delete client.io.nsps['/tour'];
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ProTable
        style={{ minWidth: 1000 }}
        headerTitle={tour && tour.catalogName}
        request={fetchAvailability}
        rowKey={record => record.performanceId}
        pagination={{ size: 20 }}
        columns={columns}
        toolBarRender={() => [
          refreshing ? (
            <Button key="button" icon={<SyncOutlined />} type="primary" disabled>
              Refreshing
            </Button>
          ) : (
            <Button key="button" icon={<SyncOutlined />} type="primary" onClick={handleRefresh}>
              Refresh
            </Button>
          ),
        ]}
        options={false}
        onReset={() => {
          setSearchParams({});
        }}
        search={{
          searchText: 'Search',
        }}
      />
    </>
  );
};

export default LogTable;
