import qs from 'qs';
import request from '@/utils/request';

export async function newAccount(payload) {
  return request(
    '/accounts',
    {
      method: 'POST',
      body: payload,
    },
    false
  );
}

export async function getById(id) {
  return request(`/accounts/${id}`);
}

export async function accounts(payload) {
  return request(`/accounts${qs.stringify(payload, { addQueryPrefix: true })}`);
}

export async function update(id, payload) {
  return request(`/accounts/${id}`, { method: 'PATCH', body: payload });
}

export async function remove(payload) {
  return request(`/accounts/${payload}`, { method: 'DELETE' });
}
