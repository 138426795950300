import React, { useContext } from 'react';
import { message } from 'antd';
import ProForm, { ProFormText } from '@ant-design/pro-form';

import styles from './BaseView.module.less';
import { AuthContext } from '@/pages/AuthContext';
import { updateUser } from '@/services/userAPI';
import { splitName } from '@/utils/name';

const BaseView = () => {
  const auth = useContext(AuthContext);
  const user = auth.getUser();
  const [firstName, lastName] = splitName(user?.name);

  const handleFinish = async values => {
    const body = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
    };
    const res = await updateUser(user.id, body);
    if (res) {
      auth.setUser(body);
      message.success('success');
    }
  };
  return (
    <div className={styles.baseView}>
      <>
        <div className={styles.left}>
          <ProForm
            layout="vertical"
            onFinish={handleFinish}
            submitter={{
              resetButtonProps: {
                style: {
                  display: 'none',
                },
              },
              submitButtonProps: {
                children: 'update basic info',
              },
            }}
            initialValues={{
              firstName,
              lastName,
              email: user?.email,
            }}
            hideRequiredMark
          >
            <ProFormText
              width="md"
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'please enter your email!',
                },
                {
                  type: 'email',
                  message: 'please enter correct email!',
                },
              ]}
            />
            <ProFormText
              width="md"
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'please enter your first name!',
                },
              ]}
            />
            <ProFormText
              width="md"
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'please enter your last name!',
                },
              ]}
            />
          </ProForm>
        </div>
      </>
    </div>
  );
};

export default BaseView;
