export const VendorType = {
  SnApp: 'SnApp',
  Flagship: 'Flagship',
  EiffelTower: 'EiffelTower',
  Louvre: 'Louvre',
  Catacombs: 'Catacombs',
  Vatican: 'Vatican',
  StMarks: 'StMarks',
  Colosseum: 'Colosseum',
};
