import moment from 'moment';

const display = value => {
  if (value && value.purchasedNumber && value.maxTicketsPerDay) {
    const perc = (value.purchasedNumber * 100) / value.maxTicketsPerDay;
    const color = perc >= 80 ? 'green' : 'red';
    return <span style={{ color }}>{`${value.purchasedNumber}/${value.maxTicketsPerDay} ${perc.toFixed(0)}%`}</span>;
  } else {
    return '-';
  }
};

export const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    hideInSearch: true,
  },
  {
    title: 'Underground',
    dataIndex: 'DDDA3AB3-47BC-0A49-7752-0174490F632A',
    hideInSearch: true,
    render: display,
  },
  {
    title: 'Arena',
    dataIndex: 'F3CB77BD-6A43-108B-6723-0174490EB610',
    hideInSearch: true,
    render: display,
  },
  {
    title: 'Palatine_24h',
    dataIndex: 'B79E95CA-090E-FDA8-2364-017448FF0FA0',
    hideInSearch: true,
    render: display,
  },
  {
    title: 'Night',
    dataIndex: '06B97E06-CA46-981E-360A-017A9FE4C3F6',
    hideInSearch: true,
    render: display,
  },
  {
    title: 'Ticket Date',
    key: 'fromDate',
    valueType: 'dateRange',
    hideInTable: true,
    initialValue: [moment().startOf('day'), moment().add(1, 'month').endOf('day')],
  },
];
