import request from '@/utils/request';

export async function login(payload) {
  return request(
    '/auth/login',
    {
      method: 'POST',
      body: payload,
    },
    false
  );
}

export async function sendForgotPassword(email) {
  return request('/codes/send', {
    method: 'POST',
    body: {
      to: email,
      type: 'ResetPassword',
      deviceType: 'Email',
    },
  });
}

export async function resetPassword(payload) {
  return request('/auth/reset/password', {
    method: 'POST',
    body: payload,
  });
}
