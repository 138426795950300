import request from '@/utils/request';
import qs from 'qs';

export async function getList(params) {
  const res = await request(`/cron-jobs${qs.stringify(params, { addQueryPrefix: true })}`);
  if (res) {
    return { success: true, data: res, total: res.length };
  }
  return { success: false };
}

export async function getById(id) {
  return request(`/cron-jobs/${id}`);
}

export async function update(id, payload) {
  return request(`/cron-jobs/${id}`, { method: 'PATCH', body: payload });
}
