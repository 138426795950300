import { Modal, Form, Input, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as AccountAPI from '@/services/accountAPI';
import { useSelector } from 'react-redux';
import { VendorType } from '@/constants/vendor.type';
import CardSelect from '@/components/CardSelect';

const AccountEditForm = ({ showEditForm, setShowEditForm, onFinish, currentRow, setCurrentRow }) => {
  const activeVendor = useSelector(state => state.app.vendors.find(vendor => vendor.id === state.app.activeVendorId));

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const doSubmit = useCallback(
    async values => {
      setSubmitting(true);
      const res = await AccountAPI.update(currentRow.id, { ...values });
      setSubmitting(false);
      if (res && res.message) {
        setErrorMessage(res.message);
      } else {
        setErrorMessage(null);
        setShowEditForm(false);
        setCurrentRow({});
        onFinish();
        message.success('Updated new account');
      }
    },
    [onFinish, setShowEditForm, currentRow, setCurrentRow]
  );

  const onCancel = useCallback(() => {
    setShowEditForm(false);
    setCurrentRow({});
  }, [setShowEditForm, setCurrentRow]);

  const fetchAccount = useCallback(
    async id => {
      const res = await AccountAPI.getById(id);
      form.setFieldsValue({ ...res, cardId: res.card?.id });
    },
    [form]
  );

  useEffect(() => {
    if (currentRow && currentRow.id) {
      fetchAccount(currentRow.id);
    }
  }, [currentRow, fetchAccount]);

  return (
    <Modal
      title="Edit Account"
      open={showEditForm}
      width={640}
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={submitting}
      okText="Create"
    >
      <Form
        labelCol={{ span: 16, offset: 4 }}
        wrapperCol={{ span: 16, offset: 4 }}
        layout={'vertical'}
        form={form}
        onFinish={doSubmit}
        initialValues={{ vendor: 'SnAPP' }}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter account username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password placeholder="Enter account password" />
        </Form.Item>
        <Form.Item
          label="Credit Card"
          name="cardId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CardSelect />
        </Form.Item>
        {activeVendor && activeVendor.name === VendorType.Colosseum && (
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
        )}
        {activeVendor && activeVendor.name === VendorType.Colosseum && (
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
        )}
        <Form.Item>{errorMessage && <div style={{ color: '#ff4d4f' }}>{errorMessage}</div>}</Form.Item>
      </Form>
    </Modal>
  );
};

export default AccountEditForm;
