import React, { useContext, useEffect } from 'react';
import * as LoginAPI from '@/services/loginAPI';
import { AuthContext } from '@/pages/AuthContext';
import ForgetPasswordForm from './Form';
import Logo from '../login/Logo';
import styles from './index.module.css';
import { message } from 'antd';

const ForgetPassword = ({ renderLogo = () => <Logo /> }) => {
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        requestAnimationFrame(() => resize());
      },
      { passive: true }
    );
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    let fontSize = document.documentElement.clientWidth / 19.2;
    if (fontSize <= 12) {
      fontSize = 12;
    }
    document.documentElement.style.fontSize = fontSize + 'px';
  };

  const contextValue = useContext(AuthContext);
  const submit = async (values, contextValue) => {
    const response = await LoginAPI.sendForgotPassword(values.email);
    if (response && response.ok) {
      message.success('success');
    }
  };

  return (
    <div className={styles.layout}>
      {renderLogo && renderLogo()}
      <ForgetPasswordForm onFinish={values => submit(values, contextValue)} />
    </div>
  );
};

export default ForgetPassword;
