import request from '@/utils/request';
import qs from 'qs';

export async function notifications(params) {
  const res = await request(`/notifications${qs.stringify(params, { addQueryPrefix: true })}`);
  if (res.content) {
    return { success: true, data: res.content, total: res.total };
  }
  return { success: false };
}

export async function markAsRead() {
  return request('/notifications/mark-as-read', { method: 'POST' });
}
