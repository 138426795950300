import { Tooltip } from 'antd';
import moment from 'moment';

export const FormatDateTime = ({ time, isUtc = false, format = 'MMM DD ,hh A' }) => {
  if (!time) {
    return null;
  }
  return (
    <Tooltip title={time.toString()}>{isUtc ? moment(time).utc().format(format) : moment(time).format(format)}</Tooltip>
  );
};
