import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale/en_US';
import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { AuthContext, AuthContextValue } from './pages/AuthContext';

ReactDOM.render(
  <Provider store={store}>
    <AuthContext.Provider value={AuthContextValue}>
      <Router>
        <ConfigProvider locale={en_US}>
          <App />
        </ConfigProvider>
      </Router>
    </AuthContext.Provider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
